import React from 'react';
import { useHistory } from 'react-router-dom';

import { useAppSelector } from './redux';

type UseOpenRegister = {
  registerAsCashier: () => void;
  registerAsPriceChecker: () => void;
};

const useOpenRegister = (): UseOpenRegister => {
  const registerSelector = useAppSelector((state) => state.register);
  const { returnMode } = useAppSelector((state) => state.return);
  const history = useHistory();

  const registerAsCashier = React.useCallback(() => {
    if (registerSelector.isCashier) {
      if (returnMode) {
        history.push('/sales/return');
      } else {
        history.push('/sales');
      }
    } else {
      history.push('/register');
    }
  }, [registerSelector.isCashier]);

  const registerAsPriceChecker = React.useCallback(() => {
    if (registerSelector.isPriceChecker) {
      history.push('/price-checker');
    }
  }, [registerSelector.lastSynced]);

  return { registerAsCashier, registerAsPriceChecker };
};

export default useOpenRegister;
