import { useCommon, useGetDiscount, useGetPosSetting, useInitProducts } from 'hooks';
import React from 'react';
import { setPercentDownload, setSyncPayload } from 'redux/reducer/registers';
import { setStructSetting } from 'redux/reducer/settings';
import commonRequest from 'services/http/common.request';
import { db } from 'services/indexdb/connection';

import { useAppDispatch, useAppSelector } from './redux';
import useGetBatchNumber from './useGetBatchNumber';
import useGetPricebook from './useGetPricebook';
import useGetSerialNumber from './useGetSerialNumber';

type initProps = {
  initDataPos: (locationId: number, currentPage: number) => Promise<void>;
  isGetCustomer: boolean;
  isGetItem: boolean;
  isGetDiscount: boolean;
  isGetPricebook: boolean;
  isGetSerialNumber: boolean;
  isGetBatchNumber: boolean;
};

const useInitSync = (): initProps => {
  const {
    getPresets,
    isGetCustomer,
    getSalesmen,
    getPayments,
    getCustomers,
    getCourier,
    getContactCategory,
    getMarketplace,
  } = useCommon();
  const { isGetItem, getAllProduct, setPickedStock, getItemStock, mappingStockToItem } =
    useInitProducts();
  const { registerInfo, pageDownload, stepDownload, location, lastSynced } = useAppSelector(
    (state) => state.register
  );
  const { getDiscount, isGetDiscount, getPromotions } = useGetDiscount();
  const { getPriceBook, isGetPricebook } = useGetPricebook();
  const { getSerialNumber, isGetSerialNumber } = useGetSerialNumber();
  const { getBatchNumber, isGetBatchNumber } = useGetBatchNumber();
  const dispatch = useAppDispatch();
  const { getPosSetting } = useGetPosSetting();
  const LIMIT_PAYLOAD = 200;

  const checkSync = async (locationId: number, process: string, page: number, lastSync: string) => {
    const getTotal = await commonRequest.initSync(
      locationId,
      registerInfo?.register_id as number,
      process,
      page,
      lastSync
    );
    const loop = Math.round(Number(getTotal.result.totalCount) / LIMIT_PAYLOAD) + 1;
    dispatch(setSyncPayload({ mustDownloading: getTotal.result.totalCount }));

    let res: Record<string, any>;
    if (getTotal) {
      for (let i = 1; i <= loop; i++) {
        const currentPage = page !== 1 ? page + i : i;
        res = await commonRequest.initSync(
          locationId,
          registerInfo?.register_id as number,
          process,
          currentPage,
          lastSync
        );

        if (process === '' && res) {
          getDiscount(res.result);
          dispatch(
            setSyncPayload({
              pageDownload: currentPage,
              mustDownloading: res.result.totalCount,
            })
          );
          dispatch(setPercentDownload({ name: 'percentDiscount', value: res.result.percent }));

          if (Number(res.result.percent) === 100) {
            setTimeout(() => {
              dispatch(setPercentDownload({ name: 'percentDiscount', value: 0 }));
              dispatch(setSyncPayload({ stepDownload: 'pricebook' }));
            }, 500);
          }
        }

        if (process === 'pricebook' && res) {
          getPriceBook(res.result);
          dispatch(
            setSyncPayload({
              pageDownload: currentPage,
              mustDownloading: res.result.totalCount,
            })
          );
          dispatch(setPercentDownload({ name: 'percentPricebook', value: res.result.percent }));
          if (Number(res.result.percent) === 100) {
            setTimeout(() => {
              dispatch(setPercentDownload({ name: 'percentPricebook', value: 0 }));
              dispatch(setSyncPayload({ stepDownload: 'serialnumber' }));
            }, 500);
          }
        }

        if (process === 'serialnumber' && res) {
          getSerialNumber(res.result);
          dispatch(
            setSyncPayload({
              pageDownload: currentPage,
              mustDownloading: res.result.totalCount,
            })
          );
          dispatch(setPercentDownload({ name: 'percentSerial', value: res.result.percent }));
          if (Number(res.result.percent) === 100) {
            setTimeout(() => {
              dispatch(setPercentDownload({ name: 'percentSerial', value: 0 }));
              dispatch(setSyncPayload({ stepDownload: 'batchnumber' }));
            }, 500);
          }
        }

        if (process === 'batchnumber' && res) {
          getBatchNumber(res.result);
          dispatch(
            setSyncPayload({
              pageDownload: currentPage,
              mustDownloading: res.result.totalCount,
            })
          );
          dispatch(setPercentDownload({ name: 'percentBatch', value: res.result.percent }));
          if (Number(res.result.percent) === 100) {
            setTimeout(() => {
              dispatch(setPercentDownload({ name: 'percentBatch', value: 0 }));
              dispatch(setSyncPayload({ stepDownload: 'contact' }));
            }, 500);
          }
        }

        if (process === 'contact' && res) {
          getCustomers(res.result);
          dispatch(
            setSyncPayload({
              pageDownload: currentPage,
              mustDownloading: res.result.totalCount,
            })
          );
          dispatch(setPercentDownload({ name: 'percentCustomer', value: res.result.percent }));
          if (Number(res.result.percent) === 100) {
            setTimeout(() => {
              dispatch(setPercentDownload({ name: 'percentCustomer', value: 0 }));
              dispatch(setSyncPayload({ stepDownload: 'item' }));
            }, 500);
          }
        }

        if (process === 'item' && res) {
          await getAllProduct(res.result);
          dispatch(
            setSyncPayload({
              mustDownloading: res.result.totalCount,
              pageDownload: currentPage,
            })
          );
          dispatch(setPercentDownload({ name: 'percentItem', value: res.result.percent }));
          if (Number(res.result.percent) === 100) {
            setTimeout(() => {
              dispatch(setPercentDownload({ name: 'percentItem', value: 0 }));
              dispatch(setSyncPayload({ stepDownload: 'stock' }));
            }, 800);
          }
        }

        if (process === 'stock' && res) {
          await getItemStock(res.result);
          setPickedStock(Number(res.result.percent));
          dispatch(
            setSyncPayload({
              pageDownload: currentPage,
              mustDownloading: res.result.totalCount,
            })
          );
          dispatch(setPercentDownload({ name: 'percentStock', value: res.result.percent }));
          if (Number(res.result.percent) === 100) {
            dispatch(setPercentDownload({ name: 'percentStock', value: 0 }));
            setTimeout(() => dispatch(setSyncPayload({ stepDownload: 'merged-item' })), 800);
          }
        }

        if (res.result.totalCount < 200) {
          break;
        }
      }
    }
  };

  const initDataPos = async (locationId: number, page: number): Promise<void> => {
    try {
      const res = await commonRequest.structPrint(locationId);
      dispatch(setStructSetting(res));
      await Promise.all([
        db.promotion.clear(),
        db.presetsdiscount.clear(),
        db.presetstax.clear(),
        db.salesmen.clear(),
        db.stock.clear(),
        db.paymentmethod.clear(),
        db.courier.clear(),
        getPosSetting(),
        getPresets('DISCOUNT'),
        getPresets('TAX'),
        getPromotions(locationId),
        getSalesmen(),
        getContactCategory(),
        getMarketplace(),
        getPayments(),
        getCourier(),
      ]);

      if (stepDownload === '')
        await checkSync(locationId, stepDownload as string, page ?? 1, lastSynced);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (stepDownload && stepDownload !== 'success' && stepDownload !== 'merged-item') {
      checkSync(Number(location?.location_id), stepDownload, pageDownload ?? 1, lastSynced);
    }

    (async () => {
      if (stepDownload === 'merged-item') {
        await mappingStockToItem();
      }
    })();
  }, [stepDownload]);

  return {
    initDataPos,
    isGetCustomer,
    isGetItem,
    isGetDiscount,
    isGetPricebook,
    isGetSerialNumber,
    isGetBatchNumber,
  };
};

export default useInitSync;
