import logsRequest from 'services/http/logs.request';

import { useAppSelector } from './redux';

interface LogsInterface {
  sendNotif: (message: string, action: string, payload?: Record<string, any>) => Promise<void>;
}

const useLogs = (): LogsInterface => {
  const { registerInfo, location } = useAppSelector((state) => state.register);
  const { profile } = useAppSelector((state) => state.auth);

  /**
   * Send payload order error to telegram channels
   * @param message
   * @param action
   * @param payload
   * @returns
   */
  const sendNotif = async (message: string, action: string, payload?: Record<string, any>) => {
    return logsRequest.sendNotif({
      errorPayload: message,
      registerName: registerInfo?.register_name ?? '',
      locationName: location?.location_name ?? '',
      email: profile?.email ?? '',
      action,
      payload,
    });
  };

  return { sendNotif };
};

export default useLogs;
