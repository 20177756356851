import { DetailOrderTransaction } from 'types';
import { IOrderReturn, IReturnList } from 'types/return.types';

import { db } from './connection';

export default {
  /**
   * Get data from IndexDB with filter type QRIS_NO_REF, NOT_SYNC, and ALL
   * @param {string} type
   * @returns {Promise<IOrderData[]>}
   * @memberof IndexDB
   */
  get: async (query = ''): Promise<IReturnList[]> => {
    const returnTransactino = await db.return
      .filter((order: IReturnList) => {
        const search = new RegExp(query.toLowerCase());

        return (
          search.test(order?.salesorder_no?.toLowerCase()) ||
          search.test(order?.pos_return_no?.toLowerCase()) ||
          (search.test(order?.customer_name?.toLowerCase()) && query !== '')
        );
      })
      .reverse()
      .toArray();

    return returnTransactino.sort((a: IReturnList, b: IReturnList) => {
      return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
    });
  },
  /**
   * Save data to IndexDB
   * @param {IOrderReturn} data
   * @returns {Promise<void>}
   * @memberof IndexDB
   * @example await return.add(data)
   */
  add: async (orderReturn: IOrderReturn): Promise<number> => {
    return db.return.add(orderReturn);
  },
  /**
   * Get data from IndexDB with type NOT_SYNC
   * @param {string} query
   * @returns {Promise<DetailOrderTransaction[]>}
   * @memberof IndexDB
   */
  getUnsync: async (query = ''): Promise<DetailOrderTransaction[]> => {
    const returnTransactino = await db.return
      .filter((orderReturn: IReturnList) => {
        const search = new RegExp(query.toLowerCase());

        return (
          (orderReturn.is_paid === 0 &&
            orderReturn.pos_return_id === 0 &&
            search.test(orderReturn?.pos_return_no?.toLowerCase())) ||
          (search.test(orderReturn?.customer_name?.toLowerCase()) && query !== '')
        );
      })
      .reverse()
      .toArray();

    return returnTransactino.sort((a: IReturnList, b: IReturnList) => {
      return new Date(b.transaction_date).getTime() - new Date(a.transaction_date).getTime();
    });
  },

  /**
   * This function is used to update the return status
   * @param {string} return_no
   * @return void
   */
  updateStatusReturn: async (noOrderReturn: string): Promise<void> => {
    await db.return.where('pos_return_no').equals(noOrderReturn).modify({ is_pay: 1 });
  },

  /**
   * This function to get all canceled return order
   *
   * @returns
   */
  getCanceled: async (): Promise<DetailOrderTransaction[]> => {
    return db.return.filter((item) => item.is_canceled === 1 && item.pos_return_id !== 0).toArray();
  },
};
