const config = {
  API_PROD: 'https://api-lb.jubelio.com',
  API_DEV: 'http://localhost:3030',
  API_STAGING: 'https://core-api-pos-v3.staging-k8s.jubelio.com',
  ACL_SALES_ALL: 815,
  ACL_SALES_PRICE_CHECKER: 817,
  ALL_LOCATION: -99,
  SUPPORT_EMAIL: 'support@jubelio.com',
  SUPPORT_EMAIL_WMS: 'support2@jubelio.com',
  WS_CONNECTION: 'ws://127.0.0.1:4444',
  SOCKETIO_DEV: 'ws://localhost:3030',
  SOCKETIO_PROD: 'wss://api-lb.jubelio.com',
  SOCKETIO_STAGING: 'wss://core-api-pos-v3.staging-k8s.jubelio.com',
  IMAGE_URL: '',
  SENTRY_DSN: 'https://64b92f9656e54d628fef813b249407a9@o1199134.ingest.sentry.io/6467266',
  POS_APP_PRINT: 'pos://pos.jubelio.com/mobile',
  API_SUPPORT_WMS: 'https://api2.jubelio.com',
};

config.IMAGE_URL = `${
  process.env.NODE_ENV === 'development' ? config.API_DEV : config.API_PROD
}/systemsetting/logo/1633251040520`;

export const PAYMENT_TYPE = {
  CASH: 1,
  DEBIT: 2,
  CREDIT: 3,
  E_MONEY: 4,
  E_WALLET: 5,
  OTHERS: 6,
  PAY_LATER: 7,
  STORE_CREDIT: 8,
  QRIS: 9,
  VOUCHER: 10,
};

export const PAYMENT = {
  CASH: -1,
  STORE_CREDIT: -3,
};

export const LIST_PAYMENT_TYPE = [
  {
    payment_type: PAYMENT_TYPE.CASH,
    name: 'Tunai',
  },
  {
    payment_type: PAYMENT_TYPE.DEBIT,
    name: 'Debit',
  },
  {
    payment_type: PAYMENT_TYPE.CREDIT,
    name: 'Kredit',
  },
  {
    payment_type: PAYMENT_TYPE.QRIS,
    name: 'QRIS',
  },
];

export const TRANSACTION_SALES_TYPE = {
  ALL: 0,
  QRIS_NO_REF: 1,
  NOT_SYNC: 3,
  RETURN: 3,
  UNPAID: 2,
};

export const PROMOTION_TYPES = {
  MinimalQuantity: 1,
  BuyXGetY: 2,
  MinimalTransaction: 3,
  FreeItemTransaction: 4,
  Member: 5,
  Voucher: 6,
};

export const ITEM_ID_TYPES = {
  Product: 'P',
  Category: 'C',
  SKU: 'I',
};

export const ITEM_TYPES = {
  BUY_X: 1,
  GET_Y: 2,
};

export const DISCOUNT_TYPES = {
  Percentage: 1,
  FixedAmount: 2,
};

export const CUSTOMER_TYPE = {
  semua_pelanggan: 1,
  kategori_pelanggan: 2,
  pelanggan: 3,
};

export const CUSTOMER_CATEGORY: Record<string, number> = {
  walkin_customer: -3,
  new_customer: -2,
};

export const JS_DAYS_MAP: Record<string, string> = {
  d0: 'Sun',
  d1: 'Mon',
  d2: 'Tue',
  d3: 'Wed',
  d4: 'Thu',
  d5: 'Fri',
  d6: 'Sat',
};

export const APPLICABLE_DAYS_MAP: Record<string, number> = {
  Mon: 1,
  Tue: 2,
  Wed: 4,
  Thu: 8,
  Fri: 16,
  Sat: 32,
  Sun: 64,
};

export const COMPANY = {
  BIBIT_BUNGA: 4095,
  MAKASSAR_HOBI: 3703,
};

export default config;
