import { PriceBook } from 'types/common.types';
import { IProductList, ProductPaging, Variant } from 'types/products.types';
import { ISerialNumber } from 'types/sales.types';

import { db } from './connection';

export default {
  /**
   * Add product to database
   * @returns {Promise<IProductList>}
   * @memberof Products
   */
  add: async (data: IProductList): Promise<boolean> => {
    try {
      await db.products.add(data);
      return Promise.resolve(true);
    } catch (error: any) {
      return error;
    }
  },

  /**
   *  Bulk add product to database
   *
   * @param data - Product list from API
   * @returns {Promise<number>} last number of product added
   */
  bulkAdd: async (data: IProductList[]): Promise<number> => {
    try {
      return db.products.bulkAdd(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * Clear all products from database
   * @returns {Promise<boolean>}
   */
  clear: async (): Promise<void> => {
    return db.products.clear();
  },

  /**
   * Get all products from database
   *
   * @param page - Page number
   * @param pageSize - Page size
   * @returns {Promise<IProductList[]>} - Product list with pagination
   */
  get: async (page: number, pageSize: number): Promise<ProductPaging> => {
    return db.products
      .orderBy('item_name')
      .offset((page - 1) * pageSize)
      .limit(pageSize)
      .toArray()
      .then((res) => ({
        listProduct: res,
        totalCount: res.length,
      }));
  },

  count: async (): Promise<number> => {
    return db.products.count();
  },

  /**
   * filter products by item_code, barcode, or item_name
   *
   * @param {string} query
   * @returns {Promise<IProductList[]>}
   * @memberof Products
   */
  filter: async (query: string): Promise<ProductPaging> => {
    const items: any = [];
    return db.products
      .filter(function (a) {
        const search = new RegExp(query.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
        // let item;
        let item = {} as Variant;
        for (let i = 0; i < a.variants.length; i++) {
          if (
            a.variants[i].item_code?.toLowerCase() === query.toLowerCase() ||
            a.variants[i].barcode?.toLowerCase() === query.toLowerCase() ||
            a.variants[i].list_serial_number?.find(
              (sn) => sn.serial_no.toLowerCase() === query.toLowerCase()
            ) ||
            a.variants[i].list_batch_number?.find(
              (bn) => bn.batch_no.toLowerCase() === query.toLowerCase()
            )
          ) {
            items.push({
              ...a,
              item_category_id: a.item_category_id,
              variants: [a.variants[i]],
            });
            item = a.variants[i];
            return items;
          }
          item = a.variants[i];
        }
        return (
          search.test(item.item_name?.toLowerCase()) ||
          search.test(item.item_code?.toLowerCase()) ||
          (search.test(item.barcode?.toLowerCase()) && query !== '' && item !== null)
        );
      })
      .toArray()
      .then((res) => {
        if (items.length > 0) {
          return {
            listProduct: items,
            totalCount: items.length,
          };
        } else {
          return {
            listProduct: res,
            totalCount: res.length,
          };
        }
      });
  },

  /**
   * Update multiple qty products in database
   *
   * @param { IProductList[]} data
   * @returns {Promise<void>}
   */
  updateQty: async (data: IProductList[]): Promise<void> => {
    try {
      data.map(async (item) => {
        await db.products
          .where('item_group_id')
          .equals(item?.item_group_id as number)
          .modify((value) => {
            value.variants.map((old) => {
              item?.variants.map((variant) => {
                if (old.item_id === variant.item_id) {
                  old.available = variant.available;
                }
                return variant;
              });
              return old;
            });
          });
      });
      return Promise.resolve();
    } catch (error: any) {
      throw Error(error);
    }
  },

  /**
   * Update multiple product information like item_name, item_code, barcode, and description
   *
   * @param { IProductList[]} data
   * @returns {Promise<void>}
   */
  updateProductInformation: async (data: IProductList[]): Promise<void> => {
    try {
      const newProduct = [];
      for (const item of data) {
        const checkProduct = await db.products
          .where('item_group_id')
          .equals(item?.item_group_id as number)
          .first();

        if (!checkProduct) {
          newProduct.push(item);
        }

        await db.products
          .where('item_group_id')
          .equals(item?.item_group_id as number)
          .modify((value) => {
            value.item_name = item.item_name;
            value.sell_price = item.sell_price;
            value.thumbnail = item.thumbnail;
            value.variants.map((old) => {
              item.variants.map((variant) => {
                if (old.item_id === variant.item_id) {
                  old.pos_check_stock = variant.pos_check_stock;
                  old.sell_price = variant.sell_price;
                  old.item_name = variant.item_name;
                  old.item_code = variant.item_code;
                  old.barcode = variant.barcode;
                  old.variation_values = variant.variation_values;
                }
                return variant;
              });
              return old;
            });
          });
      }

      if (newProduct.length > 0) await db.products.bulkAdd(newProduct);

      return Promise.resolve();
    } catch (error: any) {
      throw Error(error);
    }
  },

  /**
   * Update list_serial_number products in database
   *
   * @param { IProductList[]} data
   * @returns {Promise<void>}
   */
  updateSerialNumber: async (
    data: ISerialNumber[],
    itemGroupId: number,
    itemId: number
  ): Promise<void> => {
    try {
      data.forEach(async (sn) => {
        await db.products
          .where('item_group_id')
          .equals(itemGroupId)
          .modify((value) => {
            value.variants.forEach((old) => {
              if (old.item_id === itemId && old.list_serial_number) {
                old.list_serial_number = old.list_serial_number.filter(
                  (obj) => obj.serial_no !== sn.serial_no
                );
              }
              return old;
            });
          });
      });
      return Promise.resolve();
    } catch (error: any) {
      throw Error(error);
    }
  },

  /**
   * Update variants price book in product variant in database
   *
   * @param { PriceBook } data
   * @param { Variant[] } newVariants
   * @returns {Promise<void>}
   */
  updatePriceBook: async (data: PriceBook, newVariants: Variant[]): Promise<void> => {
    try {
      await db.products
        .where('item_group_id')
        .equals(data.item_group_id as number)
        .modify((value) => {
          value.variants = newVariants;
        });
      return Promise.resolve();
    } catch (error: any) {
      throw Error(error);
    }
  },

  /**
   * Filter product which have price_book_id inside of variant product
   *
   * @returns {Promise<IProductList[]>}
   */
  getProductWithPriceBook: async (): Promise<IProductList[]> => {
    try {
      const items: any = [];
      return db.products
        .filter(function (a) {
          for (let i = 0; i < a.variants.length; i++) {
            if (a.variants[i].price_book_id) {
              items.push({
                ...a,
                item_category_id: a.item_category_id,
                variants: [a.variants[i]],
              });
              return items;
            }
          }
        })
        .toArray();
    } catch (error: any) {
      throw Error(error);
    }
  },
  /**
   * Clear data price book in variant product
   *
   * @param {IProductList} produk
   * @returns {Promise<void>}
   */
  clearPriceBook: async (produk: IProductList): Promise<void> => {
    try {
      await db.products
        .where('item_group_id')
        .equals(produk?.item_group_id as number)
        .modify((value) => {
          value.variants.forEach((old) => {
            delete old.price_book_id;
            delete old.list_price_book;
            return old;
          });
        });
      return Promise.resolve();
    } catch (error: any) {
      throw Error(error);
    }
  },
};
