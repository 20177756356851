import {
  IContinuePayment,
  IOrderData,
  IOrderError,
  IResponseCancel,
  IResponseOrder,
} from 'types/sales.types';

import request from './request';

export default {
  sendOrder: async (
    payload: Omit<IOrderData, 'other_cost'>,
    isWmsMigrated = 'pos'
  ): Promise<IResponseOrder> => {
    try {
      const res = await request.post(`/sales/${isWmsMigrated}/v3/orders`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  continuePaymentOrder: async (payload: IContinuePayment): Promise<IResponseOrder> => {
    try {
      const res = await request.post('/sales/pos/v3/continue-payment', payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  sendErrorOrder: async (payload: IOrderError): Promise<IOrderError> => {
    try {
      const res = await request.post('/pos/save/order-errors', payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  cancelOrder: async (salesorder_id: number): Promise<IResponseCancel> => {
    try {
      const res = await request.post(`/sales/pos/v3/orders/cancel`, {
        salesorder_id,
      });
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
