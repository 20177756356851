import { DetailOrderTransaction, Maybe } from 'types';
import { IPayments } from 'types/common.types';
import {
  IBatchNumberReturn,
  IItemOrderReturn,
  IItemReturn,
  IOrderReturn,
  IReturnPayment,
  ISerialNumberReturn,
} from 'types/return.types';
import { IBatchNumber, IOrderPayment, ISerialNumber } from 'types/sales.types';
import { Items } from 'types/transaction.types';
import { amountPerItemAfterAddDiscount, dateUTCBuyItems, getAddDisc, getOtherFee } from 'utils';

export const mappingPaymentsReturn = (
  payments: IPayments[],
  remainMoney: number,
  closureId: number
): IReturnPayment[] => {
  const newArray: IOrderPayment[] = payments.map((payment: IPayments) => {
    remainMoney = payment.payment_id === -1 ? remainMoney : 0;
    const paymentAmount =
      payment.payment_id === -1
        ? Number(payment.payment_amount) - remainMoney
        : payment.payment_amount;

    return {
      payment_id: payment.payment_id,
      payment_amount: Number(paymentAmount) ?? 0,
      payment_fee: 0,
      payment_charge: remainMoney,
      account_id: payment.account_id,
      account_name: `${payment.account_code} - ${payment.account_name}`,
      notes: payment.notes ? payment.notes : '',
      no_ref: payment.no_ref ? payment.no_ref : '',
      closure_id: closureId,
    };
  });

  return newArray.length > 0 ? newArray : [];
};

export const mappingOrderReturnData = (
  orderReturn: IOrderReturn
): Omit<IOrderReturn, 'total_tax'> => {
  const newOrderReturn = { ...orderReturn };
  delete newOrderReturn.total_tax;
  delete newOrderReturn.invoice_id;
  delete newOrderReturn.is_canceled;
  delete newOrderReturn.clearOtherFee;

  return newOrderReturn;
};

export const mappingItemsReturn = (
  items: IItemReturn[],
  discount_as_service_fee: boolean
): IItemOrderReturn[] => {
  return items.map((item: IItemReturn) => ({
    // pos_return_detail_id: item.pos_return_detail_id,
    item_id: item.item_id,
    price: Number(item.sell_price),
    qty: item.qty,
    qty_in_base: item.qty_in_base,
    disc: item.disc,
    disc_amount: item.disc_amount,
    tax_amount: item.tax_amount,
    amount: amountPerItemAfterAddDiscount(item, item.addDisc, discount_as_service_fee),
    tax_id: item.tax_id,
    unit: item.unit,
    notes: item.notes ?? '',
    serial_number: item.serial_number,
    batch_number: item.batch_number,
  }));
};

export const mappingItemOrderToReturn = (order: DetailOrderTransaction): IItemReturn[] => {
  return order.items
    .filter((item) => item.amount > 0)
    .map((item: Items) => {
      const addDiscount = order.discount_as_service_fee ? -order.service_fee : -order.add_disc;
      return {
        item_id: item.item_id,
        item_name: item.item_name,
        item_code: item.item_code,
        sell_price: Number(item.price),
        price: Number(item.price),
        promotion_price: item.promotion_price ? Number(item.promotion_price) : 0,
        qty: -item.qty_in_base,
        qty_in_base: -item.qty_in_base,
        addDisc: getAddDisc(
          Number(item.price),
          -item.qty_in_base,
          Number(-order.sub_total),
          Number(addDiscount)
        ),
        otherFee: getOtherFee(
          Number(item.price),
          -item.qty_in_base,
          Number(-order.sub_total),
          Number(-order.add_fee)
        ),
        disc: Number(item.disc),
        disc_amount: (item.disc_amount / item.qty_in_base) * -item.qty_in_base,
        disc_per_qty: item.disc_amount / item.qty_in_base,
        tax_amount: -item.tax_amount,
        amount: -item.qty_in_base,
        total: -(Number(item.price) * item.qty_in_base),
        tax_id: item.tax_id,
        unit: item.unit ?? 'Buah',
        notes: item.description ?? '',
        thumbnail: item.thumbnail ?? '',
        tax_amount_qty: -item.tax_amount / -item.qty_in_base,
        serial_number: item.serial_number
          ? mappingSerialNumber(item.serial_number, item.qty_in_base)
          : null,
        batch_number: item.batch_number
          ? mappingBatchNumber(item.batch_number, item.qty_in_base)
          : null,
        is_bundle: item.is_bundle ?? false,
      };
    });
};

export function mappingSerialNumber(
  serialNumber: ISerialNumber[],
  qty: number
): ISerialNumberReturn[] {
  const newArray: ISerialNumberReturn[] =
    serialNumber &&
    serialNumber.map((item: ISerialNumber) => ({
      qty: 1,
      serial_no: item.serial_no ?? '',
      canceled: true,
      oldQty: 1,
    }));

  if (serialNumber) {
    const totalQty = serialNumber.reduce((sum, item) => sum + Number(item.amount), 0);
    if (totalQty < qty) {
      newArray.push({
        qty: qty - totalQty,
        serial_no: '-',
        canceled: true,
        oldQty: qty - totalQty,
      });
    }
  }

  return newArray;
}

export function mappingBatchNumber(batchNumber: IBatchNumber[], qty: number): IBatchNumberReturn[] {
  const newArray: IBatchNumberReturn[] =
    batchNumber &&
    batchNumber.map((item: IBatchNumber) => ({
      qty: item.qty ?? 0,
      oldQty: item.qty ?? 0,
      batch_no: item.batch_no ?? '',
      canceled: true,
    }));

  if (batchNumber) {
    const totalQty = batchNumber.reduce((sum, item) => sum + Number(item.qty), 0);
    if (totalQty < qty) {
      newArray.push({
        qty: qty - totalQty,
        oldQty: qty - totalQty,
        batch_no: '-',
        canceled: true,
      });
    }
  }

  return newArray;
}

export function mappingOrderReturn(
  orderReturn: IOrderReturn,
  order: DetailOrderTransaction,
  listItems: IItemReturn[]
): IOrderReturn {
  return {
    ...orderReturn,
    salesorder_id: order.salesorder_id,
    salesorder_no: order.salesorder_no,
    pos_return_id: 0,
    pos_return_no: order.pos_return_no,
    customer_id: order.contact_id,
    customer_name: order.contact_name,
    customer_email: order.contact_email,
    transaction_date: dateUTCBuyItems(),
    note: order.note,
    sub_total: order.sub_total ? -order.sub_total : 0,
    add_disc: order.add_disc ? -order.add_disc : 0,
    total_tax: order.total_tax ? -order.total_tax : 0,
    add_fee: order.add_fee ? -order.add_fee : 0,
    service_fee: order.service_fee ? -order.service_fee : 0,
    grand_total: order.grand_total ? -order.grand_total : 0,
    location_id: order.location_id,
    sub_minus_total: order.sub_total ? -order.sub_total : 0,
    sub_plus_total: 0,
    total_minus_tax: order.total_tax ? -order.total_tax : 0,
    total_plus_tax: 0,
    register_id: order.register_id,
    closure_id: order.closure_id,
    invoice_id: order.invoice_id,
    items: mappingItemsReturn(listItems ?? [], order.discount_as_service_fee),
    is_tax_included: order.is_tax_included,
  };
}

export function getSubMinusTotal(items: IItemReturn[]): {
  minusTotal: number;
  plustotal: number;
} {
  let minusTotal = 0;
  const plustotal = 0;
  for (const item of items) {
    minusTotal += item.amount * item.sell_price;
  }
  const data = { minusTotal, plustotal };
  return data;
}

type MappingLocalOrderItems = (listItems: Maybe<IItemReturn[]>) => IItemReturn[] | undefined;

/**
 * This method is used to combine items in cart with free items of promotion
 *
 * @param listItems
 * @returns {IItemReturn[]} - list items
 */
export const mappingLocalReturnItems: MappingLocalOrderItems = (listItems = []) => {
  return listItems?.map((item) => ({
    ...item,
    batch_number:
      item.batch_number &&
      item.batch_number.filter(
        (batchnumber) => batchnumber.canceled === true && batchnumber.batch_no !== '-'
      ),
    serial_number:
      item.serial_number &&
      item.serial_number.filter(
        (serialnumber) => serialnumber.canceled === true && serialnumber.serial_no !== '-'
      ),
  }));
};
