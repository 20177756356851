import React from 'react';
import { setOnlineStatus } from 'redux/reducer/registers';

import { useAppDispatch } from './redux';

type useOnlineStatusProps = {
  isOnline: boolean;
};

const useOnlineStatus = (): useOnlineStatusProps => {
  const [isOnline, setIsOnline] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();

  const handleOnline = () => {
    setIsOnline(true);
    dispatch(setOnlineStatus(true));
  };
  const handleOffline = () => {
    setIsOnline(false);
    dispatch(setOnlineStatus(false));
  };

  React.useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return { isOnline };
};

export default useOnlineStatus;
