import { IPosSetting } from 'types';
import { IItemReturn } from 'types/return.types';
import { ITotalDetail } from 'types/sales.types';
import { getRoundMoney } from 'utils';

import { getSubMinusTotal } from './return';

export function getTotalItemPrice(item: IItemReturn): number {
  return Number(item.sell_price) * item.qty_in_base;
}

export function getDiscountAmount(item: IItemReturn): number {
  if (item.qty < 0) {
    return item.disc_per_qty * item.qty_in_base;
  }

  return 0;
}

export function getSubTotalReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.total;
  }, 0);
}

export function getTotalDiscountReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.disc_amount;
  }, 0);
}

export function getTotalAddDiscReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.addDisc;
  }, 0);
}

export function getTotalTaxReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.tax_amount;
  }, 0);
}

export function getTotalOtherFeeReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.otherFee;
  }, 0);
}

export function getTotalItemsReturn(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + item.amount;
  }, 0);
}

export function totalAfterItemDiscount(items: IItemReturn[]): number {
  return items.reduce((sum, item) => {
    return sum + getTotalItemPrice(item) - getDiscountAmount(item);
  }, 0);
}

export function getGrandTotalReturn(
  listItem: IItemReturn[],
  settings: IPosSetting,
  clearOtherFee = false
): number {
  const saleTotal = totalAfterItemDiscount(listItem);
  const otherFee = clearOtherFee ? 0 : getTotalOtherFeeReturn(listItem);
  const totalTax = getTotalTaxReturn(listItem);
  const totalDisc = getTotalAddDiscReturn(listItem);
  const total = saleTotal - Math.abs(otherFee) + (settings.tax_included ? 0 : totalTax) - totalDisc;
  return total;
}

export const getAmountDetailReturn = (
  items: IItemReturn[],
  settings: IPosSetting,
  clearOtherFee = false
): ITotalDetail => {
  const subTotalItem = getSubTotalReturn(items);
  const discountBarang = getTotalDiscountReturn(items);
  const discountTrx = getTotalAddDiscReturn(items);
  const totalTax = getTotalTaxReturn(items);
  const otherCost = clearOtherFee ? 0 : getTotalOtherFeeReturn(items);
  const totalItems = getTotalItemsReturn(items);
  const grandTotal = getGrandTotalReturn(items, settings, clearOtherFee);
  const roundMoney = clearOtherFee ? 0 : getRoundMoney(Math.abs(grandTotal));
  const haveToPay = grandTotal - roundMoney;
  const subMinusTotal = getSubMinusTotal(items);

  return {
    subTotalItem,
    discountBarang,
    discountTrx,
    totalTax,
    otherCost,
    totalItems,
    grandTotal,
    roundMoney,
    haveToPay,
    subMinusTotal,
    getTotalTransaction: 0,
    shippingCost: 0,
    discountOutlet: 0,
    salesPromotions: 0,
    salesDiscountAmount: 0,
    clearOtherFee,
  };
};

export function amountPerItemAfterDiscount(item: IItemReturn): number {
  if (item.qty < 0) {
    return getTotalItemPrice(item) - getDiscountAmount(item);
  } else {
    return getTotalItemPrice(item);
  }
}

export function amountPerItemAfterAddDiscount(
  item: IItemReturn,
  addDisc: number,
  discount_as_service_fee: boolean
): number {
  const finalItemDiscount = amountPerItemAfterDiscount(item);
  if (discount_as_service_fee) return finalItemDiscount;

  return finalItemDiscount - addDisc;
}

export function getAddDisc(
  price: number,
  qty: number,
  subTotal: number,
  addDiscount: number
): number {
  let addDisc = 0;
  if (qty < 0) {
    addDisc = ((Number(price) * qty) / subTotal) * addDiscount;
  }

  return addDisc;
}

export function getOtherFee(price: number, qty: number, subTotal: number, addFee: number): number {
  let otherFee = 0;
  if (qty < 0) {
    otherFee = ((Number(price) * qty) / subTotal) * addFee;
  }

  return otherFee;
}
