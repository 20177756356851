import { useToast } from '@chakra-ui/react';
import loadable from '@loadable/component';
import LoadingScreen from 'components/state/LoadingScreen';
import { ToastVersion } from 'components/utils';
import { useOnlineStatus } from 'hooks';
import { useServiceWorker } from 'hooks/useServiceWorker';
import { fetcher } from 'lib/fetcher';
import { clearCache } from 'lib/helpers';
// import HomePage from 'pages/HomePage';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import AuthInit from 'routes/AuthInit';
import { db } from 'services/indexdb/connection';
import LocalStorageService from 'services/localstorage/LocalStorage.service';
import useSWR from 'swr';
import { IPosVersion } from 'types/common.types';

const Login = loadable(() => import('pages/login'), {
  fallback: <LoadingScreen />,
});

const RegisterPage = loadable(() => import('pages/registers'), {
  fallback: <LoadingScreen />,
});

const PriceCheckerPage = loadable(() => import('pages/pricelist'), {
  fallback: <LoadingScreen />,
});

const MainSetting = loadable(() => import('pages/setting'), {
  fallback: <LoadingScreen />,
});

const HomePage = loadable(() => import('pages/HomePage'), {
  fallback: <LoadingScreen />,
});

db.open();

const App: React.FC = () => {
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const toast = useToast();
  const TWO_MINUTES_REFRESH = 1000 * 60 * 2;
  const isOnline = useOnlineStatus();

  const { data: versionData } = useSWR<IPosVersion>('/pos/v3/version?appPlatform=web', fetcher, {
    refreshInterval: TWO_MINUTES_REFRESH, // 2 minutes
  });

  React.useEffect(() => {
    // check for sw updates on page change
    navigator.serviceWorker.getRegistrations().then((regs) => regs.forEach((reg) => reg.update()));
  }, []);

  React.useEffect(() => {
    if (versionData) LocalStorageService.setItem('version', versionData?.version_number);
    if (isOnline && showReload && waitingWorker) {
      toast({
        duration: 60000, // 15 second
        render: () => <ToastVersion onClick={clearCache} text='Versi baru tersedia' />,
      });
    }
  }, [waitingWorker, showReload, reloadPage, versionData]);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Login} />
        <AuthInit>
          <Route exact path='/register' component={RegisterPage} />
          <Route exact path='/price-checker' component={PriceCheckerPage} />
          <Route path='/setting' component={MainSetting} />
          <Route path='/sales' component={HomePage} />
        </AuthInit>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
