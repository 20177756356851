import { PROMOTION_TYPES } from 'constant';
import { get } from 'lodash';
import { db } from 'services/indexdb/connection';
import { ICustomerInfo, IDiscount, PriceBook, SlashPrice } from 'types/common.types';
import { IItemCart, Variant } from 'types/products.types';
import { ILocations } from 'types/register.types';
import { ISalesState } from 'types/sales.types';
import { getPriceBooks, getSinglePriceBook, validateDiscount } from 'utils/promotion';
import { validatePromoBuyXGetY } from 'utils/promotions/buyx-gety';
import { validatePromoFreeItems } from 'utils/promotions/free-items';
import { validateMinimalQuantity } from 'utils/promotions/minimal-quantity';
import { checkMinimalTransaction } from 'utils/promotions/minimal-transaction';

import { useAppSelector } from './redux';
import useCheckTime from './useCheckTime';

interface PropsPromotions {
  getSlashPrice: (items: IItemCart[]) => Promise<SlashPrice | null>;
  getPriceBookList: (items: Variant) => Promise<PriceBook>;
  getPricebookCart: (items: IItemCart[]) => Promise<PriceBook>;
  getTransactionPromotion: (sales: ISalesState) => Promise<any>;
  getPromotionItem: (items: IItemCart[]) => Promise<any>;
}

const usePromotions = (): PropsPromotions => {
  const { location } = useAppSelector((state) => state.register);
  const { listItemCart, customerInfo } = useAppSelector((state) => state.sales);
  const { currentHours } = useCheckTime();

  const populatePriceBook = async () => {
    const result: Record<string, any> = [];
    const allPriceBook = await db.pricebook.toArray();
    const sortPriceBook = allPriceBook.sort((a, b) =>
      a.min_unit < b.min_unit ? -1 : a.min_unit > b.min_unit ? 1 : 0
    );
    for (const item of sortPriceBook) {
      const ik = `item_${item.item_id}` as string;
      if (result[ik] === undefined) result[ik] = [];
      result[ik].push(item);
    }

    return result;
  };

  /**
   *  Get slash price for items
   */
  const getSlashPrice = async (itemsCart: IItemCart[]): Promise<SlashPrice | null> => {
    const result = await db.discount.toArray();
    const listDiscountByItem: SlashPrice = {} as SlashPrice;
    for (const items of itemsCart) {
      const itemsDiscount: IDiscount = result.find(
        (discount: IDiscount) => discount.item_id === items.item_id
      );
      if (itemsDiscount) {
        if (!validateDiscount(itemsDiscount, customerInfo as ICustomerInfo, currentHours))
          return null;

        if (!itemsDiscount.is_applied || itemsDiscount.is_stopped) return null;
        if (itemsDiscount.location_id !== location?.location_id) return null;

        const promotion_price = Number(itemsDiscount.promotion_price);
        const ik = `item_${itemsDiscount.item_id}`;
        const slash_price = `slash_price_${ik}`;
        const total_slash_price = `total_slash_price_${ik}`;
        if (promotion_price > 0) {
          if (promotion_price >= items.sell_price) {
            listDiscountByItem[total_slash_price] = 0;
          } else {
            const existingItem = listItemCart.find(
              (item: IItemCart) => item.item_id === items.item_id
            );
            let qty = 1;

            if (existingItem) qty = existingItem.quantity;

            listDiscountByItem[total_slash_price] =
              Number(qty) * (Number(items.sell_price) - promotion_price);
            listDiscountByItem['item_id'] = existingItem?.item_id as number;
            listDiscountByItem[ik] = itemsDiscount.item_id;
            listDiscountByItem['discount_id'] = itemsDiscount.discount_id;
            listDiscountByItem[slash_price] = promotion_price;
          }
        } else {
          listDiscountByItem[total_slash_price] = 0;
        }
      }
    }

    return Object.keys(listDiscountByItem).length > 0 ? listDiscountByItem : null;
  };

  /**
   * Get price book list for items
   */
  const getPriceBookList = async (variant: Variant): Promise<PriceBook> => {
    const populatePriceBook = await db.pricebook.toArray();
    const listPriceBookItem: PriceBook = {} as PriceBook;

    const ik = `item_${variant.item_id}`;
    const price_book_id = `price_book_id_item_${variant.item_id}`;
    const list_price_book = `list_price_book_item_${variant.item_id}`;
    const itemPriceBook = populatePriceBook.filter(
      (pricebook: PriceBook) => pricebook.item_id === variant.item_id
    );
    if (itemPriceBook.length > 0) {
      const priceBookPrice = getSinglePriceBook(
        itemPriceBook,
        customerInfo as ICustomerInfo,
        currentHours,
        location?.location_id as number,
        variant
      );

      const priceBookList = getPriceBooks(
        itemPriceBook,
        customerInfo as ICustomerInfo,
        currentHours,
        location?.location_id as number
      );

      if (priceBookPrice !== null) {
        listPriceBookItem['price'] = Number(priceBookPrice);
        listPriceBookItem['item_id'] = variant.item_id;
        listPriceBookItem[price_book_id] =
          itemPriceBook.length > 0 && itemPriceBook[0].price_book_id;
        listPriceBookItem[list_price_book] = priceBookList;
        listPriceBookItem['retail_price'] = priceBookPrice;
      } else listPriceBookItem[ik] = 0;
    }

    return listPriceBookItem;
  };

  /**
   * Get price book list for items
   */
  const getPricebookCart = async (items: IItemCart[]): Promise<PriceBook> => {
    const pricebooks = await populatePriceBook();
    const listPriceBookItem: PriceBook = {} as PriceBook;
    for (const item of items) {
      const ik = `item_${item.item_id}`;
      const price_book_id = `price_book_id_item_${item.item_id}`;
      const list_price_book = `list_price_book_item_${item.item_id}`;
      const itemPriceBook = get(pricebooks, ik, []);
      const pricebookId = get(pricebooks, ik, ['price_book_id']);
      const priceBookPrice = getSinglePriceBook(
        itemPriceBook,
        customerInfo as ICustomerInfo,
        currentHours,
        location?.location_id as number,
        item
      );
      const priceBookList = getPriceBooks(
        itemPriceBook,
        customerInfo as ICustomerInfo,
        currentHours,
        location?.location_id as number
      );

      if (priceBookPrice !== null) {
        listPriceBookItem['price'] = Number(priceBookPrice);
        listPriceBookItem[ik] = Number(priceBookPrice);
        listPriceBookItem['item_id'] = item.item_id;
        listPriceBookItem[price_book_id] = itemPriceBook.length > 0 && pricebookId[0].price_book_id;
        listPriceBookItem[list_price_book] = priceBookList;
        listPriceBookItem['retail_price'] = priceBookPrice;
      } else listPriceBookItem[ik] = 0;
    }

    return listPriceBookItem;
  };

  const getPromotionItem = async (items: IItemCart[]): Promise<any> => {
    const itemPromotions = [];
    const populatePromotion = await db.promotion.toArray();

    if (populatePromotion && populatePromotion.length > 0) {
      for (const promotion of populatePromotion) {
        if (!validateDiscount(promotion, customerInfo as ICustomerInfo, currentHours)) continue;

        let p = null;
        if (promotion.promotion_type === PROMOTION_TYPES.MinimalQuantity) {
          p = validateMinimalQuantity({
            promotion,
            itemCart: items,
          });
          if (p) itemPromotions.push(p);
        }
      }
    }

    return itemPromotions;
  };

  // Promotion for Minimal Transaction, Free Item, Buy X Get Y
  const getTransactionPromotion = async (sales: ISalesState): Promise<any> => {
    const transactionPromotion = [];
    const populatePromotion = await db.promotion.toArray();
    for (const promotion of populatePromotion) {
      if (!validateDiscount(promotion, customerInfo as ICustomerInfo, currentHours)) continue;

      let p = null;
      if (promotion.promotion_type === PROMOTION_TYPES.MinimalTransaction) {
        p = checkMinimalTransaction({
          promotion,
          itemCart: sales.listItemCart,
          location: location as ILocations,
        });
        if (p) transactionPromotion.push(p);
      } else if (promotion.promotion_type === PROMOTION_TYPES.FreeItemTransaction) {
        p = validatePromoFreeItems(sales, promotion, location as ILocations);
        if (p) transactionPromotion.push(p);
      } else if (promotion.promotion_type === PROMOTION_TYPES.BuyXGetY) {
        p = validatePromoBuyXGetY(promotion, listItemCart);
        if (p) transactionPromotion.push(p);
      }
    }

    return transactionPromotion;
  };

  return {
    getSlashPrice,
    getPriceBookList,
    getPricebookCart,
    getTransactionPromotion,
    getPromotionItem,
  };
};

export default usePromotions;
