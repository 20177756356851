import React from 'react';
import commonRequest from 'services/http/common.request';
import discount from 'services/indexdb/discount';
import promotion from 'services/indexdb/promotion';
import { IDiscount, IDiscountRoot } from 'types/common.types';
import { IPromotions } from 'types/promotion.types';

type Props = {
  getDiscount: (discount: IDiscountRoot) => void;
  isGetDiscount: boolean;
  pickedDiscount: number;
  setPickedDiscount: (value: number) => void;
  getPromotions: (locationId: number) => Promise<IPromotions[]>;
};

const useGetDiscount = (): Props => {
  const [pickedDiscount, setPickedDiscount] = React.useState<number>(0);
  const [isGetDiscount, setIsGetDiscount] = React.useState<boolean>(true);

  /**
   * Trigger when socket emit "download-discount" from server
   * @param discount
   */
  const getDiscount = (discount: IDiscountRoot) => {
    try {
      setIsGetDiscount(true);
      if (discount.data.length > 0) {
        saveDiscount(discount.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Save discount to IndexedDB with chunk of data
   * @param discount
   * @returns {Promise<void>}
   */
  const saveDiscount = async (listDiscount: IDiscount[]) => {
    try {
      await discount.bulkAdd(listDiscount);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getPromotions = async (locationId: number) => {
    try {
      const res = await commonRequest.getPromotion(locationId);
      await promotion.clear();
      if (res.length > 0) {
        await promotion.bulkAdd(res);
      }
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedDiscount !== 0 && pickedDiscount === 100) {
      setIsGetDiscount(false);
      setPickedDiscount(0);
    }
  }, [pickedDiscount]);

  return {
    getDiscount,
    pickedDiscount,
    isGetDiscount,
    setPickedDiscount,
    getPromotions,
  };
};

export default useGetDiscount;
