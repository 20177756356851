import axios from 'axios';

const API_NOTIFICATION = 'https://pos-notification.vercel.app';

export default {
  sendNotif: async ({
    errorPayload,
    registerName,
    locationName,
    email,
    action,
    payload,
  }: {
    errorPayload: any;
    registerName: string;
    locationName: string;
    email: string;
    action: string;
    payload?: any;
  }): Promise<any> => {
    let message = '';

    if (errorPayload.response) {
      message = errorPayload?.response?.data.message ?? errorPayload?.response?.data.code;
    } else {
      message = errorPayload;
    }
    axios
      .post(`${API_NOTIFICATION}/api/notification`, {
        message,
        payload: errorPayload?.response?.config.data ?? JSON.stringify(payload),
        url: errorPayload?.response?.config.url ?? action,
        status: errorPayload?.response?.status ?? 200,
        email: email,
        registerName: registerName ?? '',
        location_name: locationName ?? '',
        action,
      })
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch(function (error) {
        console.log(error);
        return Promise.reject(error);
      });
  },
};
