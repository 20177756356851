import * as React from 'react';
import registerAction from 'services/http/register.request';
import { LocationFragment } from 'types/register.types';

type IuseGetLocationProps = {
  locations: LocationFragment[];
  handleSetLocation: (locationId: LocationFragment) => void;
  getLocations: (search: string) => Promise<LocationFragment[]>;
};

const useGetLocations = (): IuseGetLocationProps => {
  const PAGE_LOCATIONS = 1;
  const PAGE_SIZE_LOCATIONS = 100;
  const [locations, setLocations] = React.useState<LocationFragment>(
    [] as unknown as LocationFragment
  );
  // const authSelector = useAppSelector((state) => state.auth);

  const handleSetLocation = (location: Pick<LocationFragment, keyof LocationFragment>): void =>
    setLocations(location);

  const getLocations = async (search = ''): Promise<LocationFragment[]> => {
    try {
      const response = await registerAction.getLocations(
        PAGE_LOCATIONS,
        PAGE_SIZE_LOCATIONS,
        search
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error);
    }
  };

  return {
    locations,
    handleSetLocation,
    getLocations,
  };
};

export default useGetLocations;
