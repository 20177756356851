import { fetcher } from 'lib/fetcher';
import * as React from 'react';
import { selectLocation } from 'redux/reducer/registers';
import useSwr from 'swr';
import { ICashRegister, LocationFragment } from 'types/register.types';

import { useAppDispatch } from './redux';

type GetRegisterProps = {
  listRegister: ICashRegister;
  getRegisters: (locationId: number) => void;
  isLoading: boolean;
};

const useGetRegisters = (): GetRegisterProps => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [locationId, setLocationId] = React.useState<number>(0);
  const { data: response } = useSwr<LocationFragment>(
    locationId !== 0 ? `sales/pos/v3/locations/${locationId}/` : null,
    fetcher
  );
  const [listRegister, setListRegister] = React.useState<ICashRegister>(
    [] as unknown as ICashRegister
  );

  const getRegisters = async (locationId: number) => {
    try {
      setLoading(true);
      setLocationId(locationId);
    } catch (err: any) {
      throw new Error(err);
    } finally {
      setTimeout(() => setLoading(false), 400);
    }
  };

  React.useEffect(() => {
    if (locationId && response) {
      setListRegister({ registers: response?.registers || [] });
      dispatch(selectLocation(response));
    }
  }, [locationId, response]);

  return { listRegister, getRegisters, isLoading };
};

export default useGetRegisters;
