import React from 'react';
import { db } from 'services/indexdb/connection';
import { SerialNumber, SerialNumberRoot } from 'types/common.types';

type Props = {
  getSerialNumber: (serials: SerialNumberRoot) => void;
  isGetSerialNumber: boolean;
  pickedSerialNumber: number;
  setPickedSerialNumber: (value: number) => void;
};

const useGetSerialNumber = (): Props => {
  const [pickedSerialNumber, setPickedSerialNumber] = React.useState<number>(0);
  const [isGetSerialNumber, setIsSerialNumber] = React.useState<boolean>(true);

  /**
   * Trigger when socket emit "download-serialnumber" from server
   * @param serialnumber
   */
  const getSerialNumber = (serials: SerialNumberRoot) => {
    try {
      setIsSerialNumber(true);
      if (serials.data.length > 0) {
        saveSerialnumber(serials.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Save serialnumber to IndexedDB with chunk of data
   * @param serialnumber
   * @returns {Promise<void>}
   */
  const saveSerialnumber = async (listSerialNumber: SerialNumber[]) => {
    try {
      await db.serialnumber.bulkAdd(listSerialNumber);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedSerialNumber !== 0 && pickedSerialNumber === 100) {
      setIsSerialNumber(false);
      setPickedSerialNumber(0);
    }
  }, [pickedSerialNumber]);

  return {
    getSerialNumber,
    pickedSerialNumber,
    isGetSerialNumber,
    setPickedSerialNumber,
  };
};

export default useGetSerialNumber;
