import { AxiosResponse } from 'axios';
import { FragmentReturn, IReturnList } from 'types/return.types';
import {
  IDetailTransaction,
  IPaymentReferenceList,
  IRequestPaymentReference,
  IResponseUpdateQrisRefNo,
  ISendEmailReceipt,
  IUpdateQrisRefNo,
  TransactionFragment,
} from 'types/transaction.types';

import request from './request';

export default {
  getTransactions: async ({
    q,
    page,
    pageSize,
    locationId,
    closureId,
  }: {
    q: string;
    page: number;
    pageSize: number;
    locationId: number;
    closureId: number;
  }): Promise<TransactionFragment> => {
    try {
      const query = new URLSearchParams({
        q,
        page: String(page),
        pageSize: String(pageSize),
        location_id: String(locationId),
        closure_id: String(closureId),
        sortDirection: 'DESC',
        sortBy: 'transaction_date',
      }).toString();

      const res = await request.get(`sales/pos/v3/orders?${query}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTransactionDetail: async (salesOrderId: number): Promise<IDetailTransaction> => {
    try {
      const res = await request.get(`sales/pos/v3/orders/${salesOrderId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getReturn: async ({
    q,
    page,
    pageSize,
    locationId,
    closureId,
  }: {
    q: string;
    page: number;
    pageSize: number;
    locationId: number;
    closureId: number;
  }): Promise<FragmentReturn> => {
    try {
      const query = new URLSearchParams({
        q,
        page: String(page),
        pageSize: String(pageSize),
        location_id: String(locationId),
        closure_id: String(closureId),
        sortDirection: 'DESC',
        sortBy: 'transaction_date',
      }).toString();

      const res = await request.get(`sales/pos/v3/return/list?${query}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getReturnDetail: async (returnId: number): Promise<IReturnList> => {
    try {
      const res = await request.get(`sales/pos/v3/return/list/${returnId}`);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPaymentReferenceList: async (
    payload: IRequestPaymentReference
  ): Promise<IPaymentReferenceList[]> => {
    try {
      const res = await request.post(`sales/pos/v3/qris/referensi-pembayaran`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateQrisRefNo: async (payload: IUpdateQrisRefNo): Promise<IResponseUpdateQrisRefNo> => {
    try {
      const res = await request.post(`sales/pos/v3/qris/update-noref`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  sendEmailReceipt: async (payload: ISendEmailReceipt): Promise<AxiosResponse> => {
    try {
      const res = await request.post(`email-receipt/send`, payload);
      return Promise.resolve(res.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
