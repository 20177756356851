import { useToast } from '@chakra-ui/react';

interface NotificationProps {
  notification: (
    id: string,
    message: string,
    status: 'warning' | 'error' | 'success' | 'info',
    duration?: number
  ) => void;
}

const useNotification = (): NotificationProps => {
  const toast = useToast();

  const notification = (
    id = '',
    message: string,
    status: 'warning' | 'error' | 'success' | 'info',
    duration = 5000
  ) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        description: message ?? 'Terjadi kesalahan pada aplikasi',
        status: status,
        duration,
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  return { notification };
};

export default useNotification;
