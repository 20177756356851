import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICurrentClosure } from 'types/closure.types';
import { ILocations, PayloadSync, RegisterObject, RegisterSliceState } from 'types/register.types';

const initialState: RegisterSliceState = {
  isOnline: true,
  lastSynced: '',
  registerInfo: null,
  initSync: false,
  location: null,
  initialCash: 0,
  askInitialCash: false,
  isCashier: false,
  isPriceChecker: false,
  currentClosure: null,
  stepDownload: '',
  pageDownload: 1,
  mustDownloading: 0,
  percentDiscount: 0,
  percentBatch: 0,
  percentPricebook: 0,
  percentSerial: 0,
  percentCustomer: 0,
  percentItem: 0,
  percentStock: 0,
};

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setInitSync: (state: RegisterSliceState, action: PayloadAction<boolean>) => {
      state.initSync = action.payload;
    },
    selectLocation: (state: RegisterSliceState, action: PayloadAction<ILocations>) => {
      state.location = action.payload;
    },
    setRegisterInfo: (state: RegisterSliceState, action: PayloadAction<RegisterObject>) => {
      state.registerInfo = action.payload;
    },
    setInitialCash: (state: RegisterSliceState, action: PayloadAction<number>) => {
      state.initialCash = action.payload;
    },
    setAskInitialCash: (state: RegisterSliceState, action: PayloadAction<boolean>) => {
      state.askInitialCash = action.payload;
    },
    setAsDeviceChecker: (state: RegisterSliceState, action: PayloadAction<boolean>) => {
      state.isPriceChecker = action.payload;
    },
    setAsCashier: (state: RegisterSliceState, action: PayloadAction<boolean>) => {
      state.isCashier = action.payload;
    },
    setCurrentClosure: (
      state: RegisterSliceState,
      action: PayloadAction<ICurrentClosure | null>
    ) => {
      state.currentClosure = action.payload;
    },
    setOnlineStatus: (state: RegisterSliceState, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    removeSelectedLocation: (state: RegisterSliceState) => {
      state.location = undefined;
    },
    updateOutletDiscount: (state: RegisterSliceState, action: PayloadAction<ILocations>) => {
      const location = {
        ...state.location,
        pos_discount: Number(action.payload.pos_discount),
        pos_discount_type: action.payload.pos_discount_type,
        pos_discount_max: Number(action.payload.pos_discount_max),
        pos_tax: action.payload.pos_tax,
      };

      state.location = location as ILocations;
    },
    setSyncPayload: (state: RegisterSliceState, action: PayloadAction<PayloadSync>) => {
      return {
        ...state,
        mustDownloading: action.payload.mustDownloading,
        pageDownload: action.payload.pageDownload,
        stepDownload: action.payload.stepDownload ?? state.stepDownload,
      };
    },
    setLastSync: (state: RegisterSliceState, action: PayloadAction<Date | string>) => {
      state.lastSynced = action.payload;
    },
    setPercentDownload: (
      state: RegisterSliceState,
      action: PayloadAction<{ name: string; value: number }>
    ) => {
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    },
  },
});

export const {
  setInitSync,
  selectLocation,
  setRegisterInfo,
  setInitialCash,
  setAskInitialCash,
  setAsDeviceChecker,
  setCurrentClosure,
  setOnlineStatus,
  setAsCashier,
  removeSelectedLocation,
  updateOutletDiscount,
  setSyncPayload,
  setLastSync,
  setPercentDownload,
} = registerSlice.actions;
export default registerSlice.reducer;
