import { useGetClosure, useLogs } from 'hooks';
import returnRequest from 'services/http/return.request';
import salesRequest from 'services/http/sales.request';
import { db } from 'services/indexdb/connection';
import returnOrder from 'services/indexdb/return-order';
import { DetailOrderTransaction } from 'types';
import { IOrderReturn, IResponseOrderReturn } from 'types/return.types';
import { IOrderError } from 'types/sales.types';
import { mappingLocalReturnItems, mappingOrderReturnData } from 'utils/return';

import { useAppSelector } from './redux';

type useReturnProps = {
  sendOrderReturn: (orderReturn: IOrderReturn) => Promise<DetailOrderTransaction>;
  sendOrderReturnError: (
    orderReturn: IOrderReturn & { errorMessage: string | undefined }
  ) => Promise<IOrderError>;
  cancelReturn: (detailsReturn: DetailOrderTransaction) => Promise<DetailOrderTransaction>;
};

const useReturn = (): useReturnProps => {
  const { listItemReturn, listPayments, customer, sendStructByEmail } = useAppSelector(
    (state) => state.return
  );
  const { isOnline } = useAppSelector((state) => state.register);
  const { sendNotif } = useLogs();
  const { getClosure } = useGetClosure();

  const sendOrderReturn = async (orderReturn: IOrderReturn) => {
    let errorMessage = ''; // message error;
    try {
      const payload = mappingOrderReturnData(orderReturn);
      const isWmsMigrated = localStorage.getItem('is_pos_wms');
      let res;

      if (isOnline) {
        const resClosure = await getClosure();
        if (!resClosure) return;

        try {
          sendNotif('Buat Retur', 'create-return', payload);
          payload.is_send_email = sendStructByEmail;
          res = await returnRequest.sendReturn(payload, isWmsMigrated ?? 'pos');
        } catch (error: any) {
          errorMessage = error?.response?.data.message;
          res = { returnId: 0 };
          sendNotif(error, 'create-retur');
        }
      }

      const mappingFinalPayments = listPayments.map((item) => {
        const findPayment = payload?.payments?.find(
          (payment) => payment.payment_id === item.payment_id
        );
        if (findPayment) {
          return {
            ...item,
            payment_charge: findPayment.payment_charge,
            payment_amount: findPayment.payment_amount,
          };
        }

        return item;
      });

      const notPaid = !isOnline || res?.returnId === 0 ? 0 : 1;
      const newReturn = Object.assign({}, payload, {
        pos_return_id: res?.returnId ?? 0,
        is_paid: notPaid,
        is_return: 1,
        is_canceled: payload.is_canceled ? 1 : 0,
        hasRetur: false,
        returnMode: true,
        request_payload: JSON.stringify(payload),
        payments: mappingFinalPayments,
        total_tax: Math.abs(payload.total_minus_tax ?? 0),
        items: mappingLocalReturnItems(listItemReturn),
        contact_email:
          orderReturn.is_update_email === true ? orderReturn.customer_email : customer?.email,
        errorMessage,
      });

      const checkOrder = await db.order
        .where('salesorder_no')
        .equals(payload.salesorder_no as string)
        .first();

      if (checkOrder) {
        await db.order
          .where('salesorder_no')
          .equals(payload.salesorder_no as string)
          .modify({
            is_return: notPaid,
            hasRetur: !isOnline || res?.returnId === 0 ? false : true,
            pos_return_id: res?.returnId ?? 0,
            pos_return_no: payload.pos_return_no ?? '',
            return_canceled: false,
            errorMessage,
          });
      }

      const checkReturn = await db.return
        .where('pos_return_no')
        .equals(payload.pos_return_no ?? '')
        .first();

      if (checkReturn) {
        await db.return
          .where('pos_return_no')
          .equals(payload.pos_return_no ?? '')
          .modify({
            is_paid: notPaid,
            salesorder_id: res?.returnId ?? 0,
            errorMessage,
          });
      } else {
        await returnOrder.add(newReturn);
      }

      if (res?.contact) await saveContact(payload, res as IResponseOrderReturn);

      const orderReturnFromDb = await db.return
        .where('pos_return_no')
        .equals(payload.pos_return_no)
        .first();

      return Promise.resolve(orderReturnFromDb);
    } catch (error: any) {
      db.return
        .where('pos_return_no')
        .equals(orderReturn.pos_return_no ?? '')
        .modify({ errorMessage: errorMessage.concat(error.response.data.message) });
      sendNotif(error, 'create-retur');
      return Promise.reject(error);
    }
  };

  const saveContact = async (payload: IOrderReturn, res: IResponseOrderReturn) => {
    await db.return
      .where('pos_return_no')
      .equals(payload.pos_return_no ?? '')
      .modify({
        pos_return_id: res?.returnId ?? 0,
        is_canceled: payload.is_canceled ?? false,
      });

    await db.customer
      .where('contact_id')
      .equals(res?.contact.contact_id)
      .modify({ store_credit: res?.contact.store_credit });
  };

  const cancelReturn = async (
    detailsReturn: DetailOrderTransaction
  ): Promise<DetailOrderTransaction> => {
    try {
      const requestPayload = detailsReturn.request_payload
        ? JSON.parse(detailsReturn.request_payload)
        : detailsReturn;
      const returnPayload = {
        ...requestPayload,
        is_canceled: !isOnline ? 1 : true,
      };

      sendNotif('Pembatalan Retur', 'cancel-return', returnPayload);
      if (isOnline) {
        await returnRequest.cancelReturn(Number(detailsReturn.pos_return_id));
      }

      await db.return
        .where('pos_return_no')
        .equals(detailsReturn?.pos_return_no)
        .modify({
          is_canceled: !isOnline ? 1 : true,
          request_payload: JSON.stringify(returnPayload),
        });

      const checkOrder = await db.order
        .where('salesorder_id')
        .equals(detailsReturn?.salesorder_id ?? 0)
        .first();

      if (checkOrder) {
        await db.order
          .where('salesorder_id')
          .equals(detailsReturn?.salesorder_id ?? 0)
          .modify({
            is_return: 0,
            hasRetur: false,
            return_canceled: true,
          });
      }

      const orderReturn = await db.return
        .where('pos_return_no')
        .equals(detailsReturn.pos_return_no)
        .first();

      return Promise.resolve(orderReturn);
    } catch (error: any) {
      sendNotif(error, 'cancel-return-error');

      return Promise.reject(error);
    }
  };

  const sendOrderReturnError = async (
    orderReturn: IOrderReturn & { errorMessage: string | undefined }
  ) => {
    try {
      const paramOrderError = {
        order_error_id: 0,
        store_id: -1,
        ref_no: orderReturn.pos_return_no ?? '',
        error_message: orderReturn?.errorMessage ?? '',
        order_data: JSON.stringify(orderReturn),
      };
      let res;
      if (isOnline) {
        res = await salesRequest.sendErrorOrder(paramOrderError);
      }
      return Promise.resolve(res ?? paramOrderError);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return { sendOrderReturn, cancelReturn, sendOrderReturnError };
};

export default useReturn;
