import { createOrderAction, updateTotalSavedCart } from 'redux/reducer/sales';
import salesRequest from 'services/http/sales.request';
import transactionRequest from 'services/http/transaction.request';
import cart from 'services/indexdb/cart';
import { db } from 'services/indexdb/connection';
import orders from 'services/indexdb/orders';
import products from 'services/indexdb/products';
import { DetailOrderTransaction } from 'types';
import { IItemCart } from 'types/products.types';
import { IOrderData, IOrderError, IOrderItem, IOrderPayment } from 'types/sales.types';
import { IDetailTransaction, IPaymentsTransaction } from 'types/transaction.types';
import { mappingLocalOrderItems, mappingOrderData, mappingPaymentContinueDP } from 'utils';
import { generateSalesorderNo } from 'utils/closures';

import { useAppDispatch, useAppSelector } from './redux';
import useGetClosure from './useGetClosure';
import useLogs from './useLogs';

type userOderProps = {
  createOrder: () => void;
  getTotalSavedCart: () => void;
  sendOrder: (order: IOrderData, freeItems?: IItemCart[]) => Promise<DetailOrderTransaction>;
  sendOrderError: (
    order: IOrderData & { errorMessage: string | undefined }
  ) => Promise<IOrderError>;
  cancelOrder: (salesorderId: DetailOrderTransaction) => Promise<DetailOrderTransaction>;
  referencePaymentQris: (
    detailsOrder: DetailOrderTransaction,
    noRef: string
  ) => Promise<IDetailTransaction>;
  continuePaymentOrder: (
    order: IOrderData,
    isSync: boolean,
    freeItems?: IItemCart[]
  ) => Promise<DetailOrderTransaction>;
  getClosure: () => Promise<boolean>;
};

const useOrder = (): userOderProps => {
  const dispatch = useAppDispatch();
  const registerSelector = useAppSelector((state) => state.register);
  const { isOnline } = registerSelector;
  const authSelector = useAppSelector((state) => state.auth);
  const sales = useAppSelector((state) => state.sales);
  const { profile } = authSelector;
  const { registerInfo, location, currentClosure } = registerSelector;
  const { sendNotif } = useLogs();
  const { getClosure } = useGetClosure();

  const orderData: IOrderData = {
    salesorder_no: generateSalesorderNo({
      register_code: registerInfo?.register_code as string,
      location_code: location?.location_code as string,
    }),
    register_id: registerInfo?.register_id as number,
    location_id: location?.location_id as number,
    closure_id: currentClosure?.closure_id as number,
    user_name: profile?.email as string,
    is_paid: 0,
    pos_is_shipping: false,
  };

  const createOrder = () => {
    dispatch(createOrderAction(orderData));
  };

  const getTotalSavedCart = async (): Promise<number> => {
    const result = await cart.get(); // get all cart saved
    dispatch(updateTotalSavedCart(result.length)); // update total cart saved in redux
    return result.length;
  };

  // TODO : refactor this function
  // Split this function into two functions
  const sendOrder = async (order: IOrderData, listFreeItems?: IItemCart[]) => {
    let message = '';
    try {
      const pos_is_unpaid = order.pos_is_unpaid;
      const payload = mappingOrderData(order, listFreeItems ?? []);
      const isWmsMigrated = localStorage.getItem('is_pos_wms');

      let res;
      if (isOnline) {
        const resClosure = await getClosure();
        if (!resClosure) return;

        try {
          payload.is_send_email = sales.sendStructByEmail;
          sendNotif('Buat Transaksi', 'create-order', payload);
          res = await salesRequest.sendOrder(payload, isWmsMigrated ?? 'pos');
        } catch (error: any) {
          message = error.response?.data.message;
          sendNotif(error, 'create-order-error');
        }
      }

      const mappingFinalPayments = sales.listPayments.map((item) => {
        const findPayment = payload?.payments?.find(
          (payment) => payment.payment_id === item.payment_id
        );
        if (findPayment) {
          return {
            ...item,
            payment_charge: findPayment.payment_charge,
            payment_amount: findPayment.payment_amount,
            so_payment_id: isOnline ? 1 : 0,
          };
        }

        return item;
      });

      sales.listItemCart.forEach(async (item) => {
        if (item.serial_number) {
          await products.updateSerialNumber(item.serial_number, item.item_group_id, item.item_id);
        }
      });

      const paidAmount: number = sales.order.payments
        ? sales.order.payments.reduce((acc, cur) => acc + Number(cur.payment_amount), 0)
        : 0;

      const orderId = res?.orderId ?? 0;
      payload.pos_is_unpaid = pos_is_unpaid;
      const newOrder = Object.assign({}, payload, {
        salesorder_id: orderId,
        is_paid: !isOnline || orderId === 0 ? 0 : 1,
        request_payload: JSON.stringify(payload),
        customer_name: payload.customer?.contact_name,
        items: mappingLocalOrderItems(payload.items as IOrderItem[], sales),
        shipping_cost: Number(payload.shipping?.shipping_cost),
        contact_name: payload.customer?.contact_name,
        contact_email: payload.customer?.email,
        payments: mappingFinalPayments,
        salesmen_name: order.salesmen_name,
        payment_amount: paidAmount,
        hasRetur: false,
        return_canceled: false,
        pos_is_unpaid: pos_is_unpaid,
        errorMessage: message,
      });

      const checkOrder = await db.order
        .where('salesorder_no')
        .equals(newOrder.salesorder_no)
        .first();

      if (checkOrder) {
        await db.order
          .where('salesorder_no')
          .equals(newOrder.salesorder_no)
          .modify({
            is_paid: !isOnline || orderId === 0 ? 0 : 1,
            salesorder_id: orderId,
            is_canceled: Number(newOrder.is_canceled) === 1 ? true : false,
            pos_is_unpaid: isOnline ? pos_is_unpaid : pos_is_unpaid === true ? 1 : 0,
            errorMessage: message,
          });
      } else {
        await orders.add(newOrder);
      }

      const resContactId = res?.contact.contact_id ?? 0;
      await db.customer
        .where('email')
        .equals(payload.customer?.email ?? '')
        .modify((value) => {
          if (Number(value.contact_id) === 0) value.contact_id = resContactId;
        });

      const orderFromDb = await db.order.where('salesorder_no').equals(order.salesorder_no).first();
      return Promise.resolve(orderFromDb);
    } catch (error: any) {
      db.order
        .where('salesorder_no')
        .equals(order.salesorder_no)
        .modify({ errorMessage: message.concat(error.response?.data.message) });
      sendNotif(error, 'create-order-error');
      return Promise.reject(error);
    }
  };

  const cancelOrder = async (
    detailsOrder: DetailOrderTransaction
  ): Promise<DetailOrderTransaction> => {
    try {
      const requestPayload = detailsOrder.request_payload
        ? JSON.parse(detailsOrder.request_payload)
        : detailsOrder;

      const orderPayload = { ...requestPayload, is_canceled: !isOnline ? 1 : true };
      sendNotif('Cancel Order', 'cancel-order', orderPayload);

      if (isOnline) await salesRequest.cancelOrder(Number(detailsOrder.salesorder_id));

      // update salesorder status to canceled
      await db.order
        .where('salesorder_no')
        .equals(detailsOrder?.salesorder_no)
        .modify({
          is_canceled: !isOnline ? 1 : true,
          request_payload: JSON.stringify(orderPayload),
        });

      const order = await db.order
        .where('salesorder_no')
        .equals(detailsOrder.salesorder_no)
        .first();

      return Promise.resolve(order);
    } catch (error: any) {
      if ([400, 500].includes(error.response.status)) {
        db.order.where('salesorder_no').equals(detailsOrder?.salesorder_no).modify({
          is_canceled: true,
        });
      }
      sendNotif(error, 'cancel-order');
      return Promise.reject(error);
    }
  };

  const referencePaymentQris = async (
    detailsOrder: DetailOrderTransaction,
    noRef: string
  ): Promise<DetailOrderTransaction> => {
    try {
      if (isOnline && detailsOrder.salesorder_id !== 0) {
        const param = {
          salesorder_id: detailsOrder.salesorder_id,
          no_ref: noRef,
        };
        await transactionRequest.updateQrisRefNo(param);
      }
      const requestPayload = detailsOrder.request_payload
        ? JSON.parse(detailsOrder.request_payload)
        : detailsOrder;

      const indexPaymentQris = requestPayload.payments.findIndex(
        (value: IPaymentsTransaction) => value.payment_id === -4
      );
      if (indexPaymentQris > -1) {
        requestPayload.payments[indexPaymentQris].no_ref = noRef;
      }
      await db.order
        .where('salesorder_no')
        .equals(detailsOrder?.salesorder_no)
        .modify({
          payments: requestPayload.payments,
          request_payload: JSON.stringify(requestPayload),
        });
      const order = await db.order
        .where('salesorder_no')
        .equals(detailsOrder.salesorder_no)
        .first();

      return Promise.resolve(order);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const sendOrderError = async (order: IOrderData & { errorMessage: string | undefined }) => {
    try {
      const paramOrderError = {
        order_error_id: 0,
        store_id: -1,
        ref_no: order.salesorder_no,
        error_message: order?.errorMessage ?? '',
        order_data: JSON.stringify(order),
      };
      let res;
      if (isOnline) {
        res = await salesRequest.sendErrorOrder(paramOrderError);
      }
      return Promise.resolve(res ?? paramOrderError);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // TODO : Refactor this function and split it into smaller functions
  const continuePaymentOrder = async (
    order: IOrderData,
    isSync: boolean,
    listFreeItems?: IItemCart[]
  ) => {
    try {
      const payload = mappingOrderData(order, listFreeItems ?? []);

      if (isOnline) {
        const newPayload = {
          salesorder_id: payload.salesorder_id,
          salesorder_no: payload.salesorder_no,
          closure_id: payload.closure_id,
          payments: isSync
            ? mappingPaymentContinueDP(
                payload?.payments?.filter((item) => item.so_payment_id === 0) ?? []
              )
            : payload.payments,
          contact_id: payload.contact_id ?? -1,
          customer_name: payload.customer_name ?? 'Pelanggan Umum',
          customer_email: payload.customer?.email,
          transaction_date: payload.transaction_date,
          is_send_email: sales.sendStructByEmail,
        };
        sendNotif('Pembayaran Cicilan', 'continue-payment-order', newPayload);
        await salesRequest.continuePaymentOrder(newPayload);
      }

      const mappingFinalPayments = sales.listPayments.map((item) => {
        const findPayment = payload?.payments?.find(
          (payment) => payment.payment_id === item.payment_id
        );
        if (findPayment) {
          return {
            ...item,
            payment_charge: findPayment.payment_charge,
            payment_amount: findPayment.payment_amount,
            so_payment_id: isOnline
              ? findPayment.payment_amount
                ? findPayment.payment_amount
                : 1
              : 0,
          };
        }

        return item;
      });

      const reqPayload = order.request_payload && JSON.parse(order.request_payload);
      const checkOrder = await db.order
        .where('salesorder_no')
        .equals(payload.salesorder_no)
        .first();
      if (checkOrder && checkOrder.payments) {
        const newPayment = checkOrder.payments.map((old: IPaymentsTransaction) => {
          old.so_payment_id = old.so_payment_id ? old.so_payment_id : isOnline ? 1 : 0;
          return old;
        });

        if (isSync) {
          const paidAmount: number = newPayment.reduce(
            (acc: number, cur: IPaymentsTransaction) => acc + Number(cur.payment_amount),
            0
          );
          await db.order
            .where('salesorder_no')
            .equals(payload.salesorder_no)
            .modify({
              payments: newPayment,
              pos_is_unpaid: isOnline ? order.pos_is_unpaid : order.pos_is_unpaid === true ? 1 : 0,
              payment_amount: paidAmount,
            });

          if (reqPayload) {
            reqPayload.payments = mappingPaymentContinueDP(newPayment);
            reqPayload.pos_is_unpaid = order.pos_is_unpaid;
          }

          if (checkOrder.request_payload) {
            await db.order
              .where('salesorder_no')
              .equals(payload.salesorder_no)
              .modify({
                request_payload: JSON.stringify(reqPayload),
              });
          }
        } else {
          const paidAmount: number = checkOrder.payments
            .concat(mappingFinalPayments)
            .reduce(
              (acc: number, cur: IPaymentsTransaction) => acc + Number(cur.payment_amount),
              0
            );
          await db.order
            .where('salesorder_no')
            .equals(payload.salesorder_no)
            .modify({
              payments: checkOrder.payments.concat(mappingFinalPayments),
              pos_is_unpaid: isOnline ? order.pos_is_unpaid : order.pos_is_unpaid === true ? 1 : 0,
              payment_amount: paidAmount,
            });

          if (reqPayload) {
            reqPayload.payments = mappingPaymentContinueDP(
              checkOrder.payments.concat(mappingFinalPayments)
            );
            reqPayload.pos_is_unpaid = order.pos_is_unpaid;
          }

          if (checkOrder.request_payload) {
            await db.order
              .where('salesorder_no')
              .equals(payload.salesorder_no)
              .modify({
                request_payload: JSON.stringify(reqPayload),
              });
          }
        }
      } else {
        const paymentPayload = sales.prevPayments as unknown as IOrderPayment[];
        payload.payments = paymentPayload.concat(
          mappingFinalPayments as unknown as IOrderPayment[]
        );
      }

      const orderFromDb = await db.order.where('salesorder_no').equals(order.salesorder_no).first();
      return Promise.resolve(orderFromDb ? orderFromDb : payload);
    } catch (error: any) {
      db.order
        .where('salesorder_no')
        .equals(order.salesorder_no)
        .modify({ errorMessage: error.response.data.message });
      db.order.where('salesorder_no').equals(order.salesorder_no).first();
      sendNotif(error, 'continue-payment-order');
      return Promise.reject(error);
    }
  };

  return {
    createOrder,
    getTotalSavedCart,
    sendOrder,
    cancelOrder,
    referencePaymentQris,
    sendOrderError,
    continuePaymentOrder,
    getClosure,
  };
};

export default useOrder;
