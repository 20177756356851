/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetailOrderTransaction, IPosSetting } from 'types';
import { ICustomerInfoInput, IPayments } from 'types/common.types';
import { IReturnState } from 'types/return.types';
import { ITotalDetail } from 'types/sales.types';
import { getAddDisc, getOtherFee, getTotalDiscountReturn } from 'utils';
import {
  mappingItemOrderToReturn,
  mappingItemsReturn,
  mappingOrderReturn,
  mappingPaymentsReturn,
} from 'utils/return';

const initialState: IReturnState = {
  returnMode: false,
  listItemReturn: [],
  orderReturn: {
    salesorder_id: 1,
    salesorder_no: '',
    pos_return_id: 0,
    pos_return_no: '',
    customer_id: 1,
    customer_name: 'Pelanggan Umum',
    customer_email: '',
    is_update_email: false,
    transaction_date: '',
    is_tax_included: false,
    note: null,
    add_disc: 0,
    sub_total: 10000,
    total_disc: 0,
    total_tax: 0,
    add_fee: 0,
    service_fee: 0,
    grand_total: 10000,
    location_id: 1,
    sub_minus_total: 0,
    sub_plus_total: 0,
    total_minus_tax: 0,
    total_plus_tax: 0,
    register_id: 1,
    account_id: 1,
    invoice_id: null,
    closure_id: 0,
    is_store_credit: false,
    is_canceled: false,
    items: [],
    payments: [],
    is_send_email: false,
  },
  listPayments: [],
  duePayment: 0,
  sendStructByEmail: false,
  customer: {
    b_address: '',
    b_area: '',
    b_city: '',
    b_post_code: '',
    b_province: '',
    contact_id: -1,
    contact_name: 'Pelanggan Umum',
    contact_type: 0,
    email: '',
    location_id: 7,
    phone: '',
    is_update_email: false,
    category_id: -1,
  },
};

const methodReturn = (qty: number, method: string | 'increments' | 'decrements') => {
  return method === 'increments' ? qty + 1 : qty - 1;
};

/**
 * This function is used to increment or decrement
 * the quantity of an item batch number
 *
 * @param listReturn
 * @param payload
 * @param method
 * @returns
 */
const incrementDecrementBatch = (
  listReturn: IReturnState['listItemReturn'],
  payload: { batch_no: string; item_id: number },
  method: string
) => {
  return listReturn?.map((item) => {
    if (item.item_id === payload.item_id && item.batch_number) {
      const newBatchNumber = item.batch_number.map((batch) => {
        if (payload.batch_no === batch.batch_no) {
          const newQty = methodReturn(Number(batch.qty), method);
          if (newQty >= 0) {
            batch.qty = newQty;
            batch.canceled = newQty === 0 ? false : true;
          }
        }
        return batch;
      });
      item.batch_number = newBatchNumber;
    }
    return item;
  });
};

/**
 * This function is used to increment or decrement
 * the quantity of an item serial number
 *
 * @param listReturn
 * @param payload
 * @param method
 * @returns
 */
const incrementDecrementSerial = (
  listReturn: IReturnState['listItemReturn'],
  payload: { serial_no: string; item_id: number },
  method: string
) => {
  return listReturn?.map((item) => {
    if (item.item_id === payload.item_id && item.serial_number) {
      const newSalesReturn = item.serial_number.map((itemSn) => {
        if (payload.serial_no === itemSn.serial_no) {
          const newQty = methodReturn(Number(itemSn.qty), method);
          if (newQty >= 0) {
            itemSn.qty = newQty;
            itemSn.canceled = newQty === 0 ? false : true;
          }
        }
        return itemSn;
      });

      item.serial_number = newSalesReturn;
    }
    return item;
  });
};

export const returnSlice = createSlice({
  name: 'return-slice',
  initialState,
  reducers: {
    /**
     * This function is used to create `Return Transaction` and set into the return mode
     * to true or false
     *
     * @see DetailHistory.tsx for more details example
     */
    createOrderReturnAction: (
      state: IReturnState,
      action: PayloadAction<DetailOrderTransaction>
    ) => {
      const { payload } = action;
      const updateItems = payload.items && mappingItemOrderToReturn(payload);

      return {
        ...state,
        returnMode: true,
        listItemReturn: updateItems,
        customer: {
          ...state.customer,
          contact_id: payload.contact_id ?? 0,
          contact_name: payload.contact_name ?? '',
          contact_type: state.customer?.contact_type ?? 0,
          email: payload.contact_email ?? '',
          phone: payload.contact_phone ?? '',
          b_address: state.customer?.b_address ?? '',
          b_area: state.customer?.b_area ?? '',
          b_city: state.customer?.b_city ?? '',
          b_post_code: state.customer?.b_post_code ?? '',
          b_province: state.customer?.b_province ?? '',
          location_id: state.customer?.location_id ?? 0,
          is_update_email: state.customer?.is_update_email ?? false,
          category_id: action.payload.category_id ?? -1,
        },
        orderReturn: mappingOrderReturn(state.orderReturn, payload, updateItems ?? []),
      };
    },
    /**
     * This function is used to set customer information
     * into the Transaction Return object
     */
    setCustomerOrderReturn: (state: IReturnState, action: PayloadAction<ICustomerInfoInput>) => {
      return {
        ...state,
        customer: {
          ...state.customer,
          b_address: action.payload.s_address ?? '',
          b_area: action.payload.s_area ?? '',
          b_city: action.payload.s_city ?? '',
          b_post_code: action.payload.s_post_code ?? '',
          b_province: action.payload.s_province ?? '',
          contact_id: action.payload.contact_id,
          contact_name: action.payload.contact_name,
          contact_type: action.payload.contact_type,
          email: action.payload.email ?? '',
          location_id: action.payload.location_id ?? 0,
          phone: action.payload.phone ?? '',
          is_update_email: action.payload.is_update_email ?? false,
          category_id: action.payload.category_id ?? -1,
        },
        orderReturn: {
          ...state.orderReturn,
          customer_id: action.payload.contact_id,
          customer_name: action.payload.contact_name,
          customer_email: action.payload.email ?? '',
        },
      };
    },
    setCustomerEmailOrderReturn: (
      state: IReturnState,
      action: PayloadAction<ICustomerInfoInput>
    ) => {
      return {
        ...state,
        orderReturn: {
          ...state.orderReturn,
          customer_email: action.payload.email ?? '',
          is_update_email: action.payload.is_update_email ?? false,
        },
      };
    },
    setItemToOrderReturn: (state: IReturnState, action: PayloadAction<boolean>) => {
      const newListItemReturn =
        state.listItemReturn &&
        state.listItemReturn
          .filter((item) => item.qty_in_base !== 0)
          .map((item) => ({ ...item, tax_amount: Number(item.tax_amount) }));

      return {
        ...state,
        listItemReturn: newListItemReturn,
        orderReturn: {
          ...state.orderReturn,
          total_disc: -getTotalDiscountReturn(newListItemReturn ?? []),
          items: mappingItemsReturn(newListItemReturn ?? [], action.payload),
        },
      };
    },
    setTotalDetailToOrderReturn: (
      state: IReturnState,
      action: PayloadAction<ITotalDetail & { discount_as_service_fee: boolean }>
    ) => {
      const duePayment: number = state.listPayments.reduce(
        (acc, cur) => acc + Number(cur.payment_amount),
        0
      );
      return {
        ...state,
        orderReturn: {
          ...state.orderReturn,
          grand_total: action.payload.grandTotal - action.payload.roundMoney,
          total_minus_tax: action.payload.totalTax,
          total_disc: action.payload.discountBarang,
          add_disc: action.payload.discount_as_service_fee ? 0 : action.payload.discountTrx,
          service_fee: action.payload.discount_as_service_fee ? action.payload.discountTrx : 0,
          add_fee: -action.payload.roundMoney + action.payload.otherCost,
          total_plus_tax: 0,
          sub_plus_total: action.payload.subMinusTotal.plustotal,
          sub_minus_total: action.payload.subMinusTotal.minusTotal,
          sub_total: action.payload.subTotalItem,
          clearOtherFee: action.payload.clearOtherFee,
        },
        duePayment: duePayment - Number(action.payload.grandTotal),
      };
    },
    clearCartReturn: (state: IReturnState) => {
      state.returnMode = false;
      state.listPayments = [];
      state.listItemReturn = [];
      state.orderReturn = initialState.orderReturn;
      state.sendStructByEmail = false;
      state.customer = initialState.customer;
    },
    setPaymentMethodReturn: (
      state: IReturnState,
      action: PayloadAction<IPayments & { is_store_credit: boolean }>
    ) => {
      const newPayments = state.listPayments.concat(action.payload);
      const duePayment: number = newPayments.reduce(
        (acc, cur) => acc + Number(cur.payment_amount),
        0
      );
      return {
        ...state,
        listPayments: newPayments,
        duePayment: duePayment - Number(state.orderReturn.grand_total),
        orderReturn: {
          ...state.orderReturn,
          account_id: newPayments[0].account_id,
          is_store_credit: action.payload.is_store_credit,
          payments: mappingPaymentsReturn(
            newPayments,
            duePayment - Number(state.orderReturn.grand_total),
            state.orderReturn.closure_id ?? 0
          ),
        },
      };
    },
    deletePaymentReturn: (state: IReturnState, action: PayloadAction<number>) => {
      const newPayments = state.listPayments.filter((_item, key) => key !== action.payload);
      const duePayment: number = newPayments.reduce(
        (acc, cur) => acc + Number(cur.payment_amount),
        0
      );
      return {
        ...state,
        listPayments: newPayments,
        duePayment: duePayment - Number(state.orderReturn.grand_total),
        orderReturn: {
          ...state.orderReturn,
          payments: mappingPaymentsReturn(
            newPayments,
            duePayment,
            state.orderReturn.closure_id ?? 0
          ),
        },
      };
    },
    setSendStructByEmailReturn: (state: IReturnState, action: PayloadAction<boolean>) => {
      state.sendStructByEmail = action.payload;
    },
    removeCustomerReturn: (state: IReturnState) => {
      state.customer = initialState.customer;
      state.orderReturn.customer_id = initialState.orderReturn.customer_id;
      state.orderReturn.customer_name = initialState.orderReturn.customer_name;
      state.orderReturn.customer_email = initialState.orderReturn.customer_email;
    },
    updateQuantityItemReturn: (
      state: IReturnState,
      action: PayloadAction<{
        item_id: number;
        quantity: number;
        discount_as_service_fee: boolean;
      }>
    ) => {
      state.listItemReturn =
        state.listItemReturn &&
        state.listItemReturn.map((item) => {
          const addDiscount = action.payload.discount_as_service_fee
            ? Number(state.orderReturn.service_fee)
            : Number(state.orderReturn.add_disc);
          if (item.item_id === action.payload.item_id) {
            item.amount = Number(action.payload.quantity);
            item.qty_in_base = Number(action.payload.quantity);
            item.total = item.sell_price * Number(action.payload.quantity);
            item.addDisc = getAddDisc(
              item.price,
              action.payload.quantity,
              Number(state.orderReturn.sub_total),
              addDiscount
            );
            item.tax_amount = item.tax_amount_qty * action.payload.quantity;
            item.disc_amount = item.disc_per_qty * action.payload.quantity;
          }
          return item;
        });
    },
    decrementItemReturn: (
      state: IReturnState,
      action: PayloadAction<IPosSetting & { item_id: number }>
    ) => {
      state.listItemReturn =
        state.listItemReturn &&
        state.listItemReturn.map((item) => {
          const addDiscount = action.payload.discount_as_service_fee
            ? Number(state.orderReturn.service_fee)
            : Number(state.orderReturn.add_disc);
          if (item.item_id === action.payload.item_id) {
            const newAmount = item.amount + 1;
            if (newAmount <= 0) {
              item.qty_in_base = newAmount;
              item.amount = newAmount;
              item.total = item.sell_price * newAmount;
              item.addDisc = getAddDisc(
                item.price,
                newAmount,
                Number(state.orderReturn.sub_total),
                addDiscount
              );

              item.otherFee = getOtherFee(
                Number(item.price),
                newAmount,
                Number(state.orderReturn.sub_total),
                Number(state.orderReturn.add_fee)
              );

              item.tax_amount = item.tax_amount_qty * newAmount;
              item.disc_amount = item.disc_per_qty * newAmount;
            }
          }
          return item;
        });
    },
    incrementItemReturn: (
      state: IReturnState,
      action: PayloadAction<IPosSetting & { item_id: number }>
    ) => {
      state.listItemReturn =
        state.listItemReturn &&
        state.listItemReturn.map((item) => {
          const addDiscount = action.payload.discount_as_service_fee
            ? Number(state.orderReturn.service_fee)
            : Number(state.orderReturn.add_disc);
          if (item.item_id === action.payload.item_id) {
            const newAmount = item.amount - 1;
            if (newAmount >= item.qty) {
              item.qty_in_base = newAmount;
              item.amount = newAmount;
              item.total = item.sell_price * newAmount;
              item.addDisc = getAddDisc(
                item.price,
                newAmount,
                Number(state.orderReturn.sub_total),
                addDiscount
              );
              item.otherFee = getOtherFee(
                Number(item.price),
                newAmount,
                Number(state.orderReturn.sub_total),
                Number(state.orderReturn.add_fee)
              );
              item.tax_amount = item.tax_amount_qty * newAmount;
              item.disc_amount = item.disc_per_qty * newAmount;
            }
          }
          return item;
        });
    },
    decrementBatchNumberReturn: (
      state: IReturnState,
      action: PayloadAction<{ batch_no: string; item_id: number }>
    ) => {
      const listReturn = incrementDecrementBatch(
        state.listItemReturn,
        action.payload,
        'decrements'
      );
      state.listItemReturn = listReturn ?? [];
    },

    //#region  //*=========== Increment Decrement Batch Number of Return Transaction ===========
    incrementBatchNumberReturn: (
      state: IReturnState,
      action: PayloadAction<{ batch_no: string; item_id: number }>
    ) => {
      const listReturn = incrementDecrementBatch(
        state.listItemReturn,
        action.payload,
        'increments'
      );
      state.listItemReturn = listReturn ?? [];
    },
    updateQtyBatchNumberReturn: (
      state: IReturnState,
      action: PayloadAction<{ batch_no: string; item_id: number; qty: number }>
    ) => {
      const listReturn = state.listItemReturn?.map((item) => {
        if (item.item_id === action.payload.item_id && item.batch_number) {
          const newBatchNumber = item.batch_number.map((itemBn) => {
            if (action.payload.batch_no === itemBn.batch_no) {
              if (action.payload.qty <= itemBn.oldQty && action.payload.qty >= 0) {
                itemBn.qty = action.payload.qty;
                itemBn.canceled = action.payload.qty === 0 ? false : true;
              }
            }
            return itemBn;
          });
          item.batch_number = newBatchNumber;
        }
        return item;
      });

      state.listItemReturn = listReturn ?? [];
    },
    //#endregion  //*======== Increment Decrement Batch Number of Return Transaction ===========

    //#region  //*=========== Increments decrements Serial Number of Return Transaction ===========
    decrementSerialNumberReturn: (
      state: IReturnState,
      action: PayloadAction<{ serial_no: string; item_id: number }>
    ) => {
      const listReturn = incrementDecrementSerial(
        state.listItemReturn,
        action.payload,
        'decrements'
      );
      state.listItemReturn = listReturn ?? [];
    },
    incrementSerialNumberReturn: (
      state: IReturnState,
      action: PayloadAction<{ serial_no: string; item_id: number }>
    ) => {
      const listReturn = incrementDecrementSerial(
        state.listItemReturn,
        action.payload,
        'increments'
      );
      state.listItemReturn = listReturn ?? [];
    },
    //#endregion  //*======== Increments decrements Serial Number of Return Transaction ===========

    updateQtySerialNumberReturn: (
      state: IReturnState,
      action: PayloadAction<{ serial_no: string; item_id: number; qty: number }>
    ) => {
      const listReturn = state.listItemReturn?.map((item) => {
        if (item.item_id === action.payload.item_id && item.serial_number) {
          item.serial_number = item.serial_number.map((itemSn) => {
            if (action.payload.serial_no === itemSn.serial_no) {
              if (action.payload.qty <= 1 && action.payload.qty >= 0) {
                itemSn.qty = action.payload.qty;
                itemSn.canceled = action.payload.qty === 0 ? false : true;
              }
            }
            return itemSn;
          });
        }
        return item;
      });

      state.listItemReturn = listReturn ?? [];
    },
  },
});

export const {
  createOrderReturnAction,
  setCustomerOrderReturn,
  setCustomerEmailOrderReturn,
  clearCartReturn,
  setPaymentMethodReturn,
  deletePaymentReturn,
  setSendStructByEmailReturn,
  removeCustomerReturn,
  updateQuantityItemReturn,
  decrementItemReturn,
  incrementItemReturn,
  setItemToOrderReturn,
  setTotalDetailToOrderReturn,
  decrementBatchNumberReturn,
  incrementBatchNumberReturn,
  updateQtyBatchNumberReturn,
  decrementSerialNumberReturn,
  incrementSerialNumberReturn,
  updateQtySerialNumberReturn,
} = returnSlice.actions;

export default returnSlice.reducer;
