import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from 'constant';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

let API_URL = '';
if (process.env.NODE_ENV === 'production') {
  API_URL = process.env.REACT_APP_API_URL_PROD || config.API_PROD;
} else {
  API_URL = config.API_PROD;
}

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  config.headers['Authorization'] = LocalStorageService.getItem('token');
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    if (error.config && error.response && error.response.status === 401) {
      window.location.href = '/';
      LocalStorageService.clear();
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

axios.defaults.baseURL = API_URL;
axios.interceptors.request.use(onRequest, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);

export default axios;
