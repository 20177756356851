import { PickBankNote } from 'types/register.types';
import { dateCode, timeTransaction } from 'utils';

type ObjectGenerateOrderNo = {
  register_code: string;
  location_code: string;
};
type OBjectGenerateReturnNo = ObjectGenerateOrderNo & {
  closure_id: number;
};
type GenerateSalesOrdrNo = (data: ObjectGenerateOrderNo) => string;
type GenerateReturnNo = (data: OBjectGenerateReturnNo) => string;

/**
 * This method is used to generate the sales order number for the sales order
 * @param {ObjectGenerateOrderNo} data - The data to generate the sales order number
 * @returns {string} - The sales order number
 */
export const generateSalesorderNo: GenerateSalesOrdrNo = ({ register_code, location_code }) => {
  const date = dateCode();
  const time = timeTransaction();

  return `JP/${location_code}/${register_code}/${date}/${time}`;
};

/**
 * This method is used to generate the return order number for the sales order
 * @param {OBjectGenerateReturnNo} data - The data to generate the return order number
 * @returns {string} - The return order number
 */
export const generatePosreturnNo: GenerateReturnNo = ({
  register_code,
  location_code,
  closure_id,
}) => {
  const date = dateCode();
  const time = timeTransaction();

  return `JP/${location_code}/${register_code}/RET/${closure_id}/${date}/${time}`;
};

export const amountBankNotes = (listBanksNote: PickBankNote[]): number => {
  return listBanksNote.reduce((sum, item) => {
    return sum + Number(item?.banknote_count) * Number(item.note_amount);
  }, 0);
};
