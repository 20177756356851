import config from 'constant';
import { DeepLinker } from 'lib/deeplinker';
import { isMobile } from 'utils/user-agent';

interface IUsePrintProps {
  dataPrint: any;
  onErroPrint?: () => void | Promise<any>;
  onSuccessPrint?: () => void | Promise<any>;
  onIgnorePrint?: () => void | Promise<any>;
}

export declare type IUsePrintHookReturn = () => any;

const usePrint = (props: IUsePrintProps): any => {
  const { dataPrint, onErroPrint, onSuccessPrint, onIgnorePrint } = props;

  if (isMobile()) {
    const linker = DeepLinker({
      onIgnored: function () {
        onIgnorePrint && onIgnorePrint();
        return true;
      },
      onFallback: function () {
        return true;
      },
    });
    linker.openUrl(`${config.POS_APP_PRINT}/${Buffer.from(dataPrint).toString('base64')}`);
    return true;
  }

  const socket = new WebSocket(config.WS_CONNECTION);
  socket.onopen = async () => socket.send(dataPrint);
  socket.onmessage = (event) => {
    const res = JSON.parse(event.data);
    if (res && res.message === 'error') onErroPrint && onErroPrint();
    if (res && res.message === 'success') onSuccessPrint && onSuccessPrint();
  };
  socket.onclose = () => {
    onIgnorePrint && onIgnorePrint(); // trigger react-to-print if socket print closed
    socket.close();
  };
};

export default usePrint;
