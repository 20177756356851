import React from 'react';
import { setInitSync, setSyncPayload } from 'redux/reducer/registers';
import batchnumber from 'services/indexdb/batch-number';
import { db } from 'services/indexdb/connection';
import products from 'services/indexdb/products';
import serialnumber from 'services/indexdb/serial-number';
import { IListStock, IProductList, IProductRoot } from 'types/products.types';

import { useAppDispatch, useAppSelector } from './redux';
import usePromotions from './usePromotions';

type useInitProductProps = {
  getAllProduct: (productList: IProductRoot<IProductList[]>) => Promise<boolean>;
  pickedItem: number;
  pickedStock: number;
  totalItem: number;
  isGetItem: boolean;
  setTotalItem: (totalItem: number) => void;
  setPickedItem: (pickedItem: number) => void;
  setPickedStock: (pickedStock: number) => void;
  getItemStock: (listStock: IProductRoot<IListStock[]>) => Promise<boolean>;
  mappingStockToItem: () => Promise<void>;
};

const useInitProducts = (): useInitProductProps => {
  const [pickedItem, setPickedItem] = React.useState<number>(0);
  const [totalItem, setTotalItem] = React.useState<number>(0);
  const [isGetItem, setIsGetItem] = React.useState<boolean>(true);
  const [pickedStock, setPickedStock] = React.useState<number>(0);
  const { getPriceBookList } = usePromotions();
  const dispatch = useAppDispatch();
  const { lastSynced } = useAppSelector((state) => state.register);

  const getAllProduct = async (productList: IProductRoot<IProductList[]>) => {
    try {
      setIsGetItem(true);
      // run partials request get products from websocket
      // and save into indexDB
      if (productList.data.length > 0) {
        const listProduct = [];
        for (const items of productList.data) {
          const variants = [];
          for (const variant of items.variants) {
            const pricebook = await getPriceBookList(variant);
            const listSerialNumber = await serialnumber.getByItemId(variant.item_id);
            const listBatchNumber = await batchnumber.getByItemId(variant.item_id);
            if (pricebook !== undefined && pricebook.item_id === variant.item_id) {
              variants.push({
                ...variant,
                available: 0,
                normal_price: variant.sell_price,
                price_book_id: pricebook[`price_book_id_item_${variant.item_id}`],
                list_price_book: pricebook[`list_price_book_item_${variant.item_id}`],
                list_serial_number: listSerialNumber,
                list_batch_number: listBatchNumber,
              });
            } else {
              variants.push({
                ...variant,
                available: 0,
                list_price_book: [],
                list_serial_number: listSerialNumber,
                list_batch_number: listBatchNumber,
              });
            }
          }

          listProduct.push({
            ...items,
            variants: variants,
          });
        }

        if (lastSynced !== '') {
          await products.updateProductInformation(listProduct);
          return Promise.resolve(true);
        }

        await products.bulkAdd(listProduct);
        return Promise.resolve(true);
      }

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const mappingStockToItem = async (): Promise<void> => {
    const listProduct = await db.products.toArray();
    const listStock = await db.stock.toArray();
    const mergedProduct: IProductList[] = [];
    for (const product of listProduct) {
      for (const stock of listStock) {
        if (product.item_group_id === stock.item_group_id) {
          const checkVariant = product.variants.map((v) => {
            const child = stock.items?.find((i) => i.item_id === v.item_id);
            if (child) {
              return {
                ...v,
                available: child.available,
              };
            }
            return v;
          });

          if (checkVariant) {
            mergedProduct.push({
              ...product,
              variants: checkVariant,
            });
          }
        }
      }
    }

    await db.products.bulkPut(mergedProduct);
    setTimeout(() => {
      dispatch(setInitSync(false));
      dispatch(setSyncPayload({ stepDownload: '' }));
    }, 5000);
  };

  const getItemStock = async (listStocks: IProductRoot<IListStock[]>) => {
    try {
      if (listStocks.data.length > 0) {
        const dropStock: IListStock[] = [];
        for (const listStock of listStocks.data) {
          dropStock.push(listStock);
        }
        await db.stock.bulkAdd(dropStock);
      }

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedItem !== 0 && pickedItem === 100) {
      setTimeout(() => {
        setIsGetItem(false);
      }, 1000);
    }
  }, [pickedItem]);

  React.useEffect(() => {
    if (pickedStock !== 0 && pickedStock === 100) {
      setTimeout(() => {
        dispatch(setInitSync(false));
      }, 1000);
    }
  }, [pickedStock]);

  return {
    getAllProduct,
    pickedItem,
    totalItem,
    isGetItem,
    setTotalItem,
    setPickedItem,
    getItemStock,
    pickedStock,
    setPickedStock,
    mappingStockToItem,
  };
};

export default useInitProducts;
