import { AxiosError } from 'axios';
import request from 'services/http/request';
import { BankNotesFragment, ICashRegister, LocationsFragments } from 'types/register.types';

export default {
  getLocations: async (page: number, pageSize: number, q = ''): Promise<LocationsFragments> => {
    try {
      const response = await request.get(
        `sales/pos/v3/locations/?page=${page}&pageSize=${pageSize}&q=${q}`
      );
      return Promise.resolve(response.data);
    } catch (err) {
      const error = err as Error | AxiosError;
      return Promise.reject(error);
    }
  },
  getCashRegisters: async (location_id: number): Promise<ICashRegister> => {
    try {
      const response = await request.get(`sales/pos/v3/locations/${location_id}/`);
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  },
  getBankNotes: async (): Promise<BankNotesFragment> => {
    try {
      const response = await request.get(`sales/pos/v3/banknotes`);
      return Promise.resolve(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
