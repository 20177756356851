import paymentMethod from 'services/indexdb/payment-method';
import { IPayments } from 'types/common.types';

interface IUsePayments {
  getListPayments: (paymentType: number) => Promise<IPayments[]>;
}

const usePayments = (): IUsePayments => {
  const getListPayments = async (paymentType: number): Promise<IPayments[]> => {
    const response = await paymentMethod.filter(paymentType, 'payment_type');
    return response;
  };

  return {
    getListPayments,
  };
};

export default usePayments;
