import React from 'react';
import { db } from 'services/indexdb/connection';
import { PriceBook, PricebookRoot } from 'types/common.types';

type Props = {
  getPriceBook: (discount: PricebookRoot) => void;
  isGetPricebook: boolean;
  pickedPricebook: number;
  setPickedPricebook: (value: number) => void;
};

const useGetPricebook = (): Props => {
  const [pickedPricebook, setPickedPricebook] = React.useState<number>(0);
  const [isGetPricebook, setIsPricebook] = React.useState<boolean>(true);

  /**
   * Trigger when socket emit "download-discount" from server
   * @param discount
   */
  const getPriceBook = (discount: PricebookRoot) => {
    try {
      setIsPricebook(true);
      if (discount.data.length > 0) {
        saveDiscount(discount.data);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * Save discount to IndexedDB with chunk of data
   * @param discount
   * @returns {Promise<void>}
   */
  const saveDiscount = async (listDiscount: PriceBook[]) => {
    try {
      await db.pricebook.bulkAdd(listDiscount);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    if (pickedPricebook !== 0 && pickedPricebook === 100) {
      setIsPricebook(false);
      setPickedPricebook(0);
    }
  }, [pickedPricebook]);

  return {
    getPriceBook,
    pickedPricebook,
    isGetPricebook,
    setPickedPricebook,
  };
};

export default useGetPricebook;
