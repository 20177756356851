import { Image } from '@chakra-ui/react';
import trackerAssist from '@openreplay/tracker-assist';
import trackerAxios from '@openreplay/tracker-axios';
import clsx from 'clsx';
import { useNotification } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { tracker } from 'lib/helpers';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { userInfo } from 'redux/reducer/auth';
import authRequest from 'services/http/auth.request';
import LocalStorageService from 'services/localstorage/LocalStorage.service';

type AuthProps = {
  children: React.ReactNode;
};
const AuthInit: React.FC<AuthProps> = ({ children }: AuthProps) => {
  const dispatch = useAppDispatch();
  const token = LocalStorageService.getItem('token');
  const authState = useAppSelector((state) => state.auth);
  const { notification } = useNotification();
  const router = useHistory();

  const INTERVAL_TEN_MINUTES = 300000;
  const [isLoading, setLoading] = React.useState<boolean>(true);

  tracker.use(
    trackerAxios({
      ignoreHeaders: false,
    })
  );
  tracker.use(trackerAssist());
  tracker.start();
  tracker.setUserID(authState.profile?.email ?? '');

  const requestUser = React.useCallback(async () => {
    try {
      const wmsUrl = authState.is_wms_migrated ? 'pos-wms' : 'pos';
      const res = await authRequest.getUserInfo(wmsUrl);
      dispatch(userInfo(res));
    } catch (error: any) {
      if (error.response && error.response.status === 400) {
        notification('', 'Session anda berakhir', 'warning', 5000);
        router.push('/login');
      }
    }
  }, [token, authState.is_wms_migrated]);

  React.useEffect(() => {
    requestUser();
  }, [token]);

  React.useEffect(() => {
    const reqUserInfo = setInterval(() => requestUser(), INTERVAL_TEN_MINUTES);
    return () => {
      clearInterval(reqUserInfo);
    };
  }, [token]);

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          className={clsx(
            'fixed inset-0 flex h-screen w-screen items-center justify-center bg-white'
          )}
        >
          <div className='flex max-w-[500px] flex-wrap items-center justify-center gap-x-8'>
            <div className='flex flex-col items-center justify-center'>
              <Image src='/jubelio-pos.png' w='full' alt='jubelio-pos' className='scale-50' />
              <p className='loading -mt-2 font-bold'>Sedang menyiapkan halaman...</p>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </React.Fragment>
  );
};

export default AuthInit;
