import { CUSTOMER_CATEGORY, CUSTOMER_TYPE } from 'constant';
import { ICustomerInfo, IDiscount, PriceBook } from 'types/common.types';
import { Variant } from 'types/products.types';
import { IPromotions } from 'types/promotion.types';

const checkDayIsApplied = (dayApplied: number): boolean => {
  const arr = [64, 32, 16, 8, 4, 2, 1];
  const currentDate = new Date();
  const arrApplied = [];
  const dayOfWeek = [];
  for (let i = 0; i < arr.length; i++) {
    if (dayApplied - arr[i] < 0) {
      continue;
    } else if (dayApplied - arr[i] === 0) {
      arrApplied.push(arr[i]);
      break;
    } else {
      arrApplied.push(arr[i]);
      dayApplied -= arr[i];
    }
  }
  for (let i = 0; i < arrApplied.length; i++) {
    switch (arrApplied[i]) {
      case 64:
        dayOfWeek.push(0);
        break;
      case 32:
        dayOfWeek.push(6);
        break;
      case 16:
        dayOfWeek.push(5);
        break;
      case 8:
        dayOfWeek.push(4);
        break;
      case 4:
        dayOfWeek.push(3);
        break;
      case 2:
        dayOfWeek.push(2);
        break;
      case 1:
        dayOfWeek.push(1);
        break;
      default:
        break;
    }
  }
  if (dayOfWeek.includes(currentDate.getDay())) {
    return true;
  } else {
    return false;
  }
};

// TODO: Refactoring this file name after finish all features
export function validateDiscount(
  discount: IDiscount | PriceBook | IPromotions,
  customer: ICustomerInfo,
  currentHours: number
): boolean {
  // date's checking
  const today = new Date();
  const startDate = new Date(discount.start_date);
  const endDate = new Date(discount.end_date);
  if (today < startDate || today > endDate) return false;

  // day checking
  if (!checkDayIsApplied(discount.days_applicable)) {
    return false;
  }

  const customerIds =
    discount.customers && discount.customers.length > 0
      ? discount.customers.map((c) => c.c_id)
      : [];

  if (discount.customer_type === CUSTOMER_TYPE.pelanggan) {
    const cid = customer?.contact_id ?? -1;
    if (!customerIds.includes(cid)) {
      return false;
    }
  }

  // checking category customer
  if (discount.customer_type === CUSTOMER_TYPE.kategori_pelanggan) {
    const cid = Number(customer?.category_id);
    if (
      !customerIds.includes(cid) &&
      !(customerIds.includes(CUSTOMER_CATEGORY.walkin_customer) && customer === null) &&
      !(
        customerIds.includes(CUSTOMER_CATEGORY.new_customer) &&
        customer !== null &&
        customer === undefined
      )
    ) {
      return false;
    }
  }

  // time checking
  const times = discount.times ?? [];
  if (times.length > 0) {
    let timesMatched = false;
    for (const time of times) {
      if (currentHours >= time.start_time && currentHours < time.end_time) {
        timesMatched = true;
        break;
      }
    }

    if (!timesMatched) return false;
  }

  return true;
}

/**
 * Get single pricebook not have default qty
 *
 * @param listItemPriceBook
 * @param customer
 * @param currentHours
 * @param locationId
 * @param itemsCart
 */
export function getSinglePriceBook(
  listItemPriceBook: PriceBook[],
  customer: ICustomerInfo,
  currentHours: number,
  locationId: number,
  itemsCart: Variant
): number {
  let priceBookPrice = 0;

  const firstQty = listItemPriceBook.length > 0 ? listItemPriceBook[0].min_unit : 0;
  const sortPriceBook = listItemPriceBook.sort((a, b) => {
    if (a.min_unit === b.min_unit) {
      return a.price_book_detail_id - b.price_book_detail_id;
    }
    return a.min_unit > b.min_unit ? 1 : -1;
  });

  for (const resultSort of sortPriceBook) {
    if (!validateDiscount(resultSort, customer, currentHours)) continue;
    if (!resultSort.is_applied || resultSort.is_stopped) continue;
    if (resultSort.location_id !== locationId) continue;

    const minUnit = resultSort.min_unit;
    priceBookPrice =
      Number(itemsCart.quantity) < firstQty
        ? Number(itemsCart.normal_price)
        : Number(itemsCart.quantity) >= minUnit
        ? Number(resultSort.retail_price)
        : priceBookPrice;
  }

  return priceBookPrice;
}

/**
 * Get pricebooks and return to array
 *
 * @param listItemPriceBook
 * @param customer
 * @param currentHours
 * @param locationId
 * @param itemsCart
 */
export function getPriceBooks(
  listItemPriceBook: PriceBook[],
  customer: ICustomerInfo,
  currentHours: number,
  locationId: number
): PriceBook[] {
  const listPriceBook: PriceBook[] = [];
  const sortPriceBook = listItemPriceBook.sort((a, b) => {
    if (a.min_unit === b.min_unit) {
      return a.price_book_detail_id - b.price_book_detail_id;
    }
    return a.min_unit > b.min_unit ? 1 : -1;
  });

  let minUnit = null;

  for (const resultSort of sortPriceBook) {
    if (!validateDiscount(resultSort, customer, currentHours)) continue;
    if (!resultSort.is_applied || resultSort.is_stopped) continue;
    if (resultSort.location_id !== locationId) continue;

    if (minUnit === resultSort.min_unit) {
      const objIndex = listPriceBook.findIndex((obj) => obj.min_unit === resultSort.min_unit);
      if (objIndex > -1) {
        listPriceBook.splice(objIndex, 1);
      }
    }

    listPriceBook.push(resultSort);
    minUnit = resultSort.min_unit;
  }

  return listPriceBook;
}
