import { DISCOUNT_TYPES, PROMOTION_TYPES } from 'constant';
import { get } from 'lodash';
import { IItemCart } from 'types/products.types';
import { getChecklistsDiscountRes, TransactionPromotion } from 'types/promotion.types';

import { formatBuyXGetY } from './buyx-gety';
import { formatFreeItemTransaction } from './free-items';
import { formatMinimalQuantity } from './minimal-quantity';
import { formatMinimalTransaction } from './minimal-transaction';

type ChecklistDiscountResp = {
  [key: string]: {
    discount: number;
    total: number;
    items: IItemCart[];
  };
};

export const formatPromotion = (promotions: TransactionPromotion[]): unknown => {
  const result = [];
  for (const promotion of promotions) {
    if (promotion.rules.promotion_type === PROMOTION_TYPES.MinimalTransaction) {
      result.push(formatMinimalTransaction(promotion));
    } else if (promotion.rules.promotion_type === PROMOTION_TYPES.MinimalQuantity) {
      result.push(formatMinimalQuantity(promotion));
    } else if (promotion.rules.promotion_type === PROMOTION_TYPES.FreeItemTransaction) {
      result.push(formatFreeItemTransaction(promotion));
    } else if (promotion.rules.promotion_type === PROMOTION_TYPES.BuyXGetY) {
      result.push(formatBuyXGetY(promotion));
    }
  }

  return result;
};
/**
 * Func to get total discount of promotion Minimal Quantity
 *
 * @param promotion
 * @returns
 */
export const getChecklistsDiscount = (
  promotion: getChecklistsDiscountRes
): ChecklistDiscountResp => {
  const discount: Record<string, any> = {};
  let totalPrice = 0;

  for (const ckey in promotion.checkLists) {
    discount[ckey] = {
      discount: 0,
      total: 0,
      items: promotion.checkLists[ckey].items,
      min_qty: 0,
    };

    for (const item of promotion.checkLists[ckey]?.items ?? []) {
      let item_price = item.sell_price as number;
      if (!promotion.rules.ignore_item_discount)
        item_price -= get(item, 'pos_slash_price', 0) / item.quantity;

      // discount[ckey].min_qty += promotion.checkLists[ckey].expected;
      discount[ckey].total += item_price;
    }
    totalPrice += discount[ckey].total;
  }

  let totalDiscount = 0;
  for (const ckey in discount) {
    if (promotion.rules.discount_type === DISCOUNT_TYPES.Percentage) {
      discount[ckey].discount =
        (discount[ckey].total * promotion.rules.discount_amount) /
        100 /
        discount[ckey].items.length;
    } else {
      discount[ckey].discount =
        (discount[ckey].total * promotion.rules.discount_amount) / totalPrice;
    }

    if (promotion.rules.multiplicative && Number(promotion?.multiplier) > 1) {
      discount[ckey].discount = discount[ckey] * Number(promotion.multiplier ?? 0);
    }
    totalDiscount += discount[ckey].discount;
  }

  if (
    promotion.rules.discount_type === DISCOUNT_TYPES.Percentage &&
    promotion.rules.total_discount > 0 &&
    totalDiscount > promotion.rules.total_discount
  ) {
    for (const ckey in discount) {
      discount[ckey].discount =
        (discount[ckey].discount * promotion.rules.total_discount) / totalDiscount;
    }
  }

  return discount;
};

export function getItemPromotion(item: IItemCart, promotions: TransactionPromotion[]): number {
  let itemPromotion = 0;
  const listPromo = promotions ? promotions : [];
  for (const p of listPromo) {
    itemPromotion += p['itemsDiscountDetails'][`p_${item.item_id}`] ?? 0;
  }

  return itemPromotion;
}

export function getSalesPromotions(promotions: TransactionPromotion[]): number {
  let discount = 0;
  for (const p of promotions) {
    const promoWithoutFreeItems = get(p, 'transactionDiscount');
    if (promoWithoutFreeItems) {
      discount += p.transactionDiscount ?? 0;
    }
  }

  return discount;
}

export function getListFreeItems(listTransactionPromotion: TransactionPromotion[]): any {
  const combinedFreeItems: any = {};
  if (listTransactionPromotion) {
    for (const promo of listTransactionPromotion) {
      // free items by sku
      for (const item of promo.free_items ?? []) {
        const ik = `key_${item.item_id}`;
        if (combinedFreeItems[ik] === undefined) combinedFreeItems[ik] = item;
        else combinedFreeItems[ik].quantity += item.quantity;
      }

      // free items by product
      for (const product of Object.values(promo.free_products ?? {})) {
        if (product.selectedItem === null) continue;

        const ik = `key_${product.selectedItem.item_id}`;
        if (combinedFreeItems[ik] === undefined) combinedFreeItems[ik] = product.selectedItem;
        else combinedFreeItems[ik].quantity += product.selectedItem.quantity;
      }

      // free items by category
      for (const category of Object.values(promo.free_categories ?? {})) {
        if (category.selectedItem === null) continue;

        const ik = `key_${category.selectedItem.item_id}`;
        if (combinedFreeItems[ik] === undefined) combinedFreeItems[ik] = category.selectedItem;
        else combinedFreeItems[ik].quantity += category.selectedItem.quantity;
      }
    }
  }

  return Object.values(combinedFreeItems);
}

export function listPromoNotFreeItems(promotions: TransactionPromotion[]): TransactionPromotion[] {
  const promolist = [];
  for (const p of promotions) {
    const promoWithoutFreeItems = get(p, 'transactionDiscount');
    if (promoWithoutFreeItems) {
      promolist.push(p);
    }
  }

  return promolist;
}
